import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

import Footer from "./components/Footer";
import Navbartop from "./components/navbar/Navbartop";

import ScrollToTop from "./ScrollToTop";
import Copyright from "./components/flacks/Copyright";
import Navbar from "./components/navbar/Navbar";
import AboutUs from "./pages/AboutUs";
import Banglasolarit from "./pages/Banglasolarit";
import CRS from "./pages/CRS";
import Career from "./pages/Career";
import Chairman from "./pages/Chairman";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Gmf from "./pages/Gmf";
import Gmm from "./pages/Gmm";
import Habitate from "./pages/Habitate";
import Home from "./pages/Home";
import Isl from "./pages/Isl";
import Md from "./pages/Md";
import Mission from "./pages/Mission";
import News from "./pages/News";
import OurPartners from "./pages/OurPartners";
import OurTeam from "./pages/OurTeam";
import Pal from "./pages/Pal";
import Pcl from "./pages/Pcl";
import Petroproduct from "./pages/Petroproduct";
import ProductsMain from "./pages/ProductsMain";
import SisterConcerns from "./pages/SisterConcerns";
import Spl from "./pages/Spl";
import Sustainability from "./pages/Sustainability";
import Vc from "./pages/Vc";
import WhoweAre from "./pages/WhoweAre";
import Category from "./pages/products/Category";
import Products from "./pages/products/Products";

function App() {
  return (
    <>
      <Router>
        <Navbartop />

        <Navbar />

        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/csr" element={<CRS />} />
            <Route path="/sister-concern" element={<SisterConcerns />} />
            <Route path="/products-main" element={<ProductsMain />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="/our-partners" element={<OurPartners />} />
            <Route path="/whoweare" element={<WhoweAre />} />
            <Route path="/isl" element={<Isl />} />
            <Route path="/pcl" element={<Pcl />} />
            <Route path="/pai" element={<Pal />} />
            <Route path="/petroproduct" element={<Petroproduct />} />
            <Route path="/banglasolar" element={<Banglasolarit />} />
            <Route path="/spl" element={<Spl />} />
            <Route path="/habitate" element={<Habitate />} />
            <Route path="/chairman" element={<Chairman />} />
            <Route path="/md" element={<Md />} />
            <Route path="/vc" element={<Vc />} />
            <Route path="/gmf" element={<Gmf />} />
            <Route path="/gmm" element={<Gmm />} />
            <Route path="/category/:id" element={<Category />} />
            <Route path="/products/:id" element={<Products />} />
            <Route path="/news-blog" element={<News />} />
          </Routes>
        </ScrollToTop>

        <Footer />

        <Copyright />
      </Router>
    </>
  );
}

export default App;
