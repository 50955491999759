export const newslink = {
  news: [
    {
      imagesrc:
        "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/182520_bangladesh_pratidin_365_LwN3lJtIy.webp?updatedAt=1701320975934",
      url: "https://www.bd-pratidin.com/agriculture-nature/2023/11/03/936160",
      title: "কুমিল্লায় উন্নত জাতের বীজ-সার পেয়ে খুশি কৃষকরা",
      details:
        "বাঁচলে কৃষক, বাঁচবে দেশ- উন্নয়নের বাংলাদেশ' প্রতিপাদ্যে কুমিল্লায় কৃষকের মাঝে উন্নত জাতের সার, বীজ ও বালাইনাশক বিতরণ করা হয়েছে। শুক্রবার জেলার সদর দক্ষিণ উপজেলার কালিকাপুরে দুই শতাধিক কৃষকের হাতে এসব সামগ্রী তুলে দেওয়া হয়। খাদ্য নিরাপত্তা নিশ্চিত করার লক্ষ্যে ঢাকা ব্যাংকের সিএসআর প্রকল্পের আওতায় এবং পেট্রোকেম বাংলাদেশ লিমিটেড সহযোগিতায় আসন্ন বোরো ...  ",
      date: new Date().getDate(),
    },
    {
      imagesrc:
        "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.29.45%20PM_kwLB1AiqT.jpeg?updatedAt=1701317185543",
      url: "https://alokitojhalokathi.com/2023/11/12/%e0%a6%ac%e0%a6%b0%e0%a6%bf%e0%a6%b6%e0%a6%be%e0%a6%b2%e0%a7%87-%e0%a6%89%e0%a6%a8%e0%a7%8d%e0%a6%a8%e0%a6%a4-%e0%a6%9c%e0%a6%be%e0%a6%a4%e0%a7%87%e0%a6%b0-%e0%a6%ac%e0%a7%80%e0%a6%9c-%e0%a6%b8/",
      title: "বরিশালে উন্নত জাতের বীজ-সার পেয়ে খুশি পাঁচ শতাধিক কৃষক",
      details:
        "রবিবার সকালে জেলার বানারীপাড়ায় পাঁচ শতাধিক কৃষকের হাতে এসব সামগ্রী তুলে দেওয়া হয়। খাদ্য নিরাপত্তা নিশ্চিত করার লক্ষ্যে ঢাকা ব্যাংকের সিএসআর প্রকল্পের আওতায় এবং পেট্রোকেম বাংলাদেশ লিমিটেড সহযোগিতায় আসন্ন বোরো মৌসুমের চাষিদের মাঝে কৃষি উপকরণ তুলে দেন অতিথিরা।  ",
      date: new Date().getDate(),
    },
    {
      imagesrc:
        "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/99-3_hOVN0ORxM.gif?updatedAt=1701322603624",
      url: "https://www.jagonatore24.com/22027/%e0%a6%a8%e0%a6%b2%e0%a6%a1%e0%a6%be%e0%a6%99%e0%a7%8d%e0%a6%97%e0%a6%be%e0%a6%af%e0%a6%bc-%e0%a6%aa%e0%a6%be%e0%a6%81%e0%a6%9a%e0%a6%b6%e0%a6%a4%e0%a6%be%e0%a6%a7%e0%a6%bf%e0%a6%95-%e0%a6%95%e0%a7%83/",
      title: "নলডাঙ্গায় পাঁচশতাধিক কৃষককে উন্নত জাতের বীজ বিতরণ",
      details:
        "নাটোর অফিস॥ ‘বাঁচলে কৃষক, বাঁচবে দেশ- উন্নয়নের বাংলাদেশ’ প্রতিপাদ্যে নাটোরে কৃষকের মাঝে উন্নত জাতের সার, বীজ ও বালাই নাশক বিনামূল্যে বিতরণ করা হয়েছে। বুধবার (১৫ নভেম্বর) দুপুরে নাটোর জেলার নলডাঙ্গা উপজেলার পাটুল বাজারে পাঁচশতাধিক কৃষকের হাতে এসব সামগ্রী তুলে দেওয়া হয়। খাদ্য নিরাপত্তা নিশ্চিত করার লক্ষ্যে ঢাকা ব্যাংকের সিএসআর প্রকল্পের আওতায় এবং পেট্রোকেম বাংলাদেশ লিমিটেড সহযোগিতায় আসন্ন বোরো ....",
      date: new Date().getDate(),
    },
    {
      imagesrc:
        "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/image-415377-1700709397_CnNPXlxyIk.jpg?updatedAt=1701323519249",
      url: "https://www.jaijaidinbd.com/wholecountry/415377",
      title: "মাদারীপুরে বীজ, সার, বালাইনাশক বিতরণ",
      details:
        "বাঁচলে কৃষক, বাঁচবে দেশ- উন্নয়নের বাংলাদেশ' প্রতিপাদ্যে কুমিল্লায় কৃষকের মাঝে উন্নত জাতের সার, বীজ ও বালাইনাশক বিতরণ করা হয়েছে। শুক্রবার জেলার সদর দক্ষিণ উপজেলার কালিকাপুরে দুই শতাধিক কৃষকের হাতে এসব সামগ্রী তুলে দেওয়া হয়। খাদ্য নিরাপত্তা নিশ্চিত করার লক্ষ্যে ঢাকা ব্যাংকের সিএসআর প্রকল্পের আওতায় এবং পেট্রোকেম বাংলাদেশ লিমিটেড সহযোগিতায় আসন্ন বোরো মৌসমের চাষিদের মাঝে কৃষি উপকরণ তুলে দেন অতিথিরা।  ",
      date: new Date().getDate(),
    },
    {
      imagesrc:
        "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/received_209797442140952_cOCZf7z14.jpeg?updatedAt=1701323694751",
      url: "https://www.doinikbartamansongbad.com/%E0%A6%A8%E0%A6%AC%E0%A7%80%E0%A6%A8%E0%A6%97%E0%A6%B0%E0%A7%87-%E0%A6%95%E0%A7%83%E0%A6%B7%E0%A6%95%E0%A6%A6%E0%A7%87%E0%A6%B0-%E0%A6%AE%E0%A6%BE%E0%A6%9D%E0%A7%87-%E0%A6%89%E0%A6%A8%E0%A7%8D/6229/",
      title: "নবীনগরে কৃষকদের মাঝে উন্নত জাতের বীজ ও সার বিতরণ",
      details:
        "মঙ্গলবার উপজেলার হাজিপুর গ্রামে প্রায় পাচঁ শতাদিক কৃষকের হাতে এসব সামগ্রী তুলে দেওয়া হয়। খাদ্য নিরাপত্তা নিশ্চিত করার লক্ষ্যে ঢাকা ব্যাংকের সিএসআর প্রকল্পের আওতায় এবং পেট্রোকেম বাংলাদেশ লিমিটেড সহযোগিতায় আসন্ন বোরো মৌসমের চাষিদের মাঝে কৃষি উপকরণ তুলে দেন অতিথিরা। বিতরণকৃত এসব উপকরণে কৃষকরা বাম্পার ফলন পাবেন বলে মন্তব্য করেছেন কৃষিবিদরা।স্থানীয় ইউপি সদস্য মোঃ বাছির মিয়ার সভাপতিত্বে বিতরণ অনুষ্ঠানে প্রধান অতিথি হিসেবে উপস্থিত ছিলেন ঢাকা ব্যাংকের আশুগঞ্জ ব্রাঞ্চের এ.বি.পি ও ম্যানেজার মোঃ মহিদুজ্জামান খান।",
      date: new Date().getDate(),
    },
    {
      imagesrc:
        "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.25.07%20PM_0R241phc6.jpeg?updatedAt=1701317180464",
      url: "https://www.facebook.com/watch/?v=1754445561668328&extid=CL-UNK-UNK-UNK-AN_GK0T-GK1C&ref=sharing&mibextid=Nif5oz",
      title: "চকরিয়ায় পাঁচ শতাধিক কৃষকদের মাঝে উন্নত জাতের বীজ ও সার বিতরণ ...",
      details:
        "‘বাঁচলে কৃষক, বাঁচবে দেশ- উন্নয়নের বাংলাদেশ’ প্রতিপাদ্যে নাটোরে কৃষকের মাঝে উন্নত জাতের সার, বীজ ও বালাই নাশক বিনামূল্যে বিতরণ করা হয়েছে। বুধবার (১৫ নভেম্বর) দুপুরে নাটোর জেলার নলডাঙ্গা উপজেলার পাটুল বাজারে পাঁচশতাধিক কৃষকের হাতে এসব সামগ্রী তুলে দেওয়া হয়। খাদ্য নিরাপত্তা নিশ্চিত করার লক্ষ্যে ঢাকা ব্যাংকের সিএসআর প্রকল্পের আওতায় এবং পেট্রোকেম বাংলাদেশ লিমিটেড সহযোগিতায় আসন্ন বোরো ....",
      date: new Date().getDate(),
    },
  ],

  gallery: [],
};

export const newsvideos = {
  vid1: {
    videolink:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Video%202023-11-29%20at%2012.17.51%20PM_wigo6Jb0ez.mp4?updatedAt=1701317177209",
    title: "মাদারীপুরে বীজ, সার, বালাইনাশক বিতরণ",
  },
  vid2: {
    videolink: "https://youtu.be/usPRnUGp-r0?si=yMfXPW65B8B0gW_3",
    title: "চকরিয়ায় পাঁচ শতাধিক কৃষকদের মাঝে উন্নত জাতের বীজ ও সার বিতরণ ||",
  },
  vid3: {
    videolink:
      "https://mega.nz/file/4ggAzBwL#fwgpgLAAhIy68bDSTCRhT4_UKUvqgigPenSruCUROX0",
    title: "নবীনগরে উন্নত জাতের বীজ ও সার পেয়ে খুশি কৃষকরা",
  },
  vid4: {
    videolink:
      "https://mega.nz/file/4ggAzBwL#fwgpgLAAhIy68bDSTCRhT4_UKUvqgigPenSruCUROX0",
    title: "কুমিল্লায় উন্নত জাতের বীজ-সার পেয়ে খুশি কৃষকরা",
  },
};
export const imagesdata = [
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.19.29%20PM_-xODWENAo.jpeg?updatedAt=1701317175211",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.19.29%20PM_-xODWENAo.jpeg?updatedAt=1701317175211",
    width: 320,
    height: 174,
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.16.02%20PM_wO8A-jq6IT.jpeg?updatedAt=1701317175624",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.16.02%20PM_wO8A-jq6IT.jpeg?updatedAt=1701317175624",
    width: 320,
    height: 212,
    caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.19.27%20PM_B2binlXZQG.jpeg?updatedAt=1701317175665",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.19.27%20PM_B2binlXZQG.jpeg?updatedAt=1701317175665",
    width: 320,
    height: 212,
    caption: "Color Pencils (Jeshu John - designerspics.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.21.46%20PM_xKCT5R8LRO.jpeg?updatedAt=1701317178483",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.21.46%20PM_xKCT5R8LRO.jpeg?updatedAt=1701317178483",
    width: 320,
    height: 213,
    caption: "Red Apples with other Red Fruit (foodiesfeed.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.21.47%20PM_cbgXyl7iz.jpeg?updatedAt=1701317178426",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.21.47%20PM_cbgXyl7iz.jpeg?updatedAt=1701317178426",
    width: 320,
    height: 183,
    caption: "37H (gratispgraphy.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.25.06%20PM_IiaVwCmDeX.jpeg?updatedAt=1701317178992",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.25.06%20PM_IiaVwCmDeX.jpeg?updatedAt=1701317178992",
    width: 240,
    height: 320,

    caption: "8H (gratisography.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.25.05%20PM_oLlUA3Xp3.jpeg?updatedAt=1701317178825",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.25.05%20PM_oLlUA3Xp3.jpeg?updatedAt=1701317178825",
    width: 320,
    height: 190,
    caption: "286H (gratisography.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.46%20PM_VxNUBg_TR9.jpeg?updatedAt=1701317182067",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.46%20PM_VxNUBg_TR9.jpeg?updatedAt=1701317182067",
    width: 320,
    height: 148,
    tags: [{ value: "Chokoria", title: "Chokoria" }],
    caption: "315H (gratisography.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.23%20PM_nDQt31VH-.jpeg?updatedAt=1701317182103",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.23%20PM_nDQt31VH-.jpeg?updatedAt=1701317182103",
    width: 320,
    height: 213,
    caption: "201H (gratisography.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.29.42%20PM_PKlz1clk47.jpeg?updatedAt=1701317184425",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.29.42%20PM_PKlz1clk47.jpeg?updatedAt=1701317184425",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
    caption: "Big Ben (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.29.45%20PM_kwLB1AiqT.jpeg?updatedAt=1701317185543",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.29.45%20PM_kwLB1AiqT.jpeg?updatedAt=1701317185543",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,

    caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.25%20PM_jp9FV8mQMM.jpeg?updatedAt=1701317181885",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.25%20PM_jp9FV8mQMM.jpeg?updatedAt=1701317181885",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "Wood Glass (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.25.05%20PM%20(1)_q-tWk_OIh.jpeg?updatedAt=1701317178685",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.25.05%20PM%20(1)_q-tWk_OIh.jpeg?updatedAt=1701317178685",
    width: 320,
    height: 213,
    caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.21.46%20PM%20(1)_32fnGQbn9.jpeg?updatedAt=1701317178198",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.21.46%20PM%20(1)_32fnGQbn9.jpeg?updatedAt=1701317178198",
    width: 320,
    height: 194,
    caption: "Old Barn (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.35.40%20PM_ohs7InpZJJ.jpeg?updatedAt=1701317187500",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.35.40%20PM_ohs7InpZJJ.jpeg?updatedAt=1701317187500",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "Cosmos Flower Macro (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.49%20PM_Ihvu8dEim.jpeg?updatedAt=1701317183972",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.49%20PM_Ihvu8dEim.jpeg?updatedAt=1701317183972",
    width: 271,
    height: 320,
    caption: "Orange Macro (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.25%20PM%20(1)_omWwu1SeW.jpeg?updatedAt=1701317181641",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.28.25%20PM%20(1)_omWwu1SeW.jpeg?updatedAt=1701317181641",
    width: 320,
    height: 213,

    caption: "Surfer Sunset (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.23.41%20PM_O1I7cUF_4.jpeg?updatedAt=1701317178547",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.23.41%20PM_O1I7cUF_4.jpeg?updatedAt=1701317178547",
    width: 320,
    height: 213,

    caption: "Man on BMX (Tom Eversley - isorepublic.com)",
  },
  {
    src: "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.16.02%20PM%20(2)_0uNWoA6QXG.jpeg?updatedAt=1701317175928",
    original:
      "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-csr/WhatsApp%20Image%202023-11-29%20at%2012.16.02%20PM%20(2)_0uNWoA6QXG.jpeg?updatedAt=1701317175928",
    width: 320,
    height: 213,
    caption: "Ropeman - Thailand (Tom Eversley - isorepublic.com)",
  },
];
