import React from 'react'
import HeaderBreadCrumb from '../components/article/HeaderBreadCrumb'
import CareerDetails from '../components/article/CareerDetails'
const Career = () => {
  return (
    <div>
     <HeaderBreadCrumb
        url="https://ik.imagekit.io/txh5evivcha5/pclstatic/office-gb6a60062b_1280_JiTe3KljL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676520006649"
        title="Career"
        link="/"
        breadCrubName="Career"
      />

      <CareerDetails/>
      
    </div>
  )
}

export default Career