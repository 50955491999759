import React from "react";
import { whoweare } from "../../data/contentdata";
const Rightwho = () => {
  return (
    <div className="lg:py-16 md:py-12 sm:py-6 xs:py-4 px-10 my-10">
      <div className="relative before:content-[''] before:bg-gradient-to-r from-[#6CBD41] to-[#27AC45] before:lg:h-[400px] before:md:h-[340px] before:md:w-[500px]  before:lg:w-[600px] before:absolute before:z-[-1] before:rounded-lg before:left-[-50px] before:top-[-50px]">
        <img
        className="mt-16"
          src="https://ik.imagekit.io/txh5evivcha5/pclstatic/opp_wGnUd5gCK.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675244270963"
          alt=""
        />
      </div>

      <p className="leading-[34px] text-[#383838] font-['Poppins'] lg:py-5 md:py-10">{whoweare.leftdescription}</p>
    </div>
  );
};

export default Rightwho;
