import React from "react";
import { FiPhoneCall , FiNavigation} from "react-icons/fi";
import { footerdata } from "../data/contentdata";
import { Link } from "react-router-dom";
import SocialLinks from "./flacks/SocialLinks";
const Footer = () => {
  const middleIndex = Math.floor(footerdata.linkdata.length / 2);
  const firstHalf = footerdata.linkdata.slice(0, middleIndex);
  const lastHalf = footerdata.linkdata.slice(middleIndex);

  return (
    <div className="lg:flex md:flex sm:block xs:block px-10 py-20 bg-[#0E0E0E] font-[Poppins]">
      <div className="lg:w-[30%] md:w-[30%] sm:w-full xs:w-full p-4 my-10">
        <img
          className="h-20"
          src="https://ik.imagekit.io/txh5evivcha5/pclstatic/logo-green-1_HZr7sZqID.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676527318106"
          alt=""
        />
        <div>
          <p className="py-5 text-white">{footerdata.leftdata.address}</p>
        </div>
        <div className="flex items-center pb-3 text-white">
          <FiPhoneCall className="text-green-600" />
          <p className="ml-3">{footerdata.leftdata.phone}</p>
        </div>

        <p className="py3 text-[#a4d646] font-semibold">Opening Hour:</p>
        <div className="py-2 text-white">
          <p>Sun – Thurs: 8 am – 5 pm,</p>
          <p>Fri-Sat: CLOSED</p>
        </div>
      </div>
      <div className="lg:w-[35%] md:w-[35%] sm:w-full xs:w-full px-10 my-10">
        <div className="py-5 text-white text-lg font-semibold relative before:content-[''] before:w-[200px] before:h-[1px] before:left-0  before:absolute before:bg-[#ffffff7a] before:top-[50px]">
          Links
        </div>

        <div className="lg:flex md:flex sm:flex xs:block ss:block ">
          {" "}
          <div className="pl-3">
            {firstHalf.map((item) => (
              <Link
                className="block py-3 text-white hover:text-green-600 transition ease-in-out divide-purple-200"
                to={item.linkpath}
              >
                {item.linkname}{" "}
              </Link>
            ))}
          </div>
          <div className="pl-6">
            {lastHalf.map((item) => (
              <Link
                className="block py-3 text-white hover:text-green-600 transition ease-in-out divide-purple-200"
                to={item.linkpath}
              >
                {item.linkname}{" "}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="lg:w-[35%] md:w-[35%] sm:w-full xs:w-full my-10">
        <div className="py-5 text-white text-xl font-semibold relative before:content-[''] before:w-[200px] before:h-[1px] before:left-0  before:absolute before:bg-[#ffffff7a] before:top-[50px]">
          Newsteller
        </div>

        <p className="text-white">Send us a newsletter to get update</p>
        <div className="relative my-5">
            <input type="text" placeholder="Enter Your Email Address" className="py-4 w-full bg-[#464646] placeholder:px-6" />
            <div className="w-20 h-full bg-gradient-to-r from-[#6CBD41] to-[#27AC45] absolute right-0 top-0 flex justify-center items-center"> <FiNavigation className="text-white text-2xl"/></div>
        </div>

        <div className="flex gap-5 my-5 flex-wrap">
            <img  src="https://ik.imagekit.io/txh5evivcha5/pclstatic/app-store_dw4cNhBEY.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677498585462" alt="" />
            <img  src="https://ik.imagekit.io/txh5evivcha5/pclstatic/google-play_fEVYygY6F.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677498585453" alt="" />
        </div>

        <div>
            <SocialLinks/>
        </div>
      </div>
    </div>
  );
};

export default Footer;
