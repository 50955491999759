import React from "react";
import CareerWithHomeVideo from "../components/article/CareerWithHomeVideo";
import HomemissionVision from "../components/article/HomemissionVision";
import Oursolutions from "../components/article/Oursolutions";
import Pclmap from "../components/article/Pclmap";
import States from "../components/article/States";
import Testimonial from "../components/article/Testimonial";
import Anmista from "../components/slider/Anmista";
import Sliderhome from "../components/slider/Sliderhome";
import GallerySectionHome from "./GallerySectionHome";
const Home = () => {
  return (
    <>
      <div className="overflow-hidden ">
        <Anmista />
        <div className="bg-[#dadada] py-10">
          <Sliderhome />
        </div>

        <HomemissionVision />
        <Oursolutions />
        <Testimonial />
        <Pclmap />
        <States />
        <GallerySectionHome />
        <CareerWithHomeVideo />
      </div>
    </>
  );
};

export default Home;
