import React from "react";


const Susleft = ({title, message, image, icon}) => {
  return (
    <div className="p-20">
      <div className=" mx-10">
        <div className="flex h-10 w-10 bg-green-600 text-white text-xl rounded-full p-2 text-center">
         {icon}
        </div>

        <h1 className="text-3xl font-semibold mx-2 text-gray-500">
          {title}
        </h1>
      </div>

      <div className="lg:flex md:flex sm:block xs:block gap-10">
        <p className="lg:w-[60%] md:w-[60%] sm:w-[100%] xs:w-[100%] p-10 text-gray-500">{message}</p>
        <div>
          <img className="w-96" src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Susleft;
