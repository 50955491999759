import React from "react";
import Teamdetails from "../components/article/Teamdetails";
import { ourteam } from "../data/contentdata";
const Gmm = () => {
  return <div className="py-24 bg-white">
    <Teamdetails name={ourteam.gmmarketing.name} designation={ourteam.gmmarketing.designation} details={ourteam.gmmarketing.shortsummary} photo={ourteam.gmmarketing.photo}/>
  </div>;
};

export default Gmm;
