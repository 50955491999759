import React from "react";
import BannerAnim, { Element } from "rc-banner-anim";
import TweenOne from "rc-tween-one";
import "rc-banner-anim/assets/index.css";
import "./Animista.css";

const BgElement = Element.BgElement;
class Anmista extends React.Component {
  render() {
    return (
      // autoPlay
      <BannerAnim prefixCls="banner-user" autoPlay>
        <Element prefixCls="banner-user-elem" key="0">
          <BgElement
            key="bg"
            className="bg bg-center bg-cover"
            style={{
              backgroundImage: `url("https://ik.imagekit.io/txh5evivcha5/pclstatic/sl-5__1__XfETrjbcx.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676779338534")`,
            }}
          />
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: "from" }}
          ></TweenOne>
          <div className="relative">
            <div className=" text-right bg-black opacity-75 lg:mt-80 md:mt-80 sm:mt-20 xs:mt-20 py-10  w-full pr-20">
              <h1 className="lg:text-5xl md:text-4xl sm:text-3xl xs:text-3xl text-white font-bold lg:py-8 md:py-8 sm:p-0 xs:p-0">
                Proper Application of Pesticide
              </h1>
              <p className="lg:text-xl md:text-xl sm:text-xl xs:text-lg ">
                Insects along with other types of pests can be quite a nuisance!
              </p>
            </div>
            <button className="absolute top-16 left-20 z-30 px-10 py-5 bg-gradient-to-r from-[#6CBD41] to-[#27AC45] rounded-md cursor-pointer text-white mt-5">
              Talk to us
            </button>
          </div>
          <TweenOne
            className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: "from", delay: 200 }}
          ></TweenOne>
        </Element>
        <Element prefixCls="banner-user-elem" key="1">
          <BgElement
            key="bg"
            className="bg bg-center bg-cover"
            style={{
              backgroundImage: `url("https://ik.imagekit.io/txh5evivcha5/pclstatic/Khalilur-Rahman_3_Q8DCBKiv6.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676779332375")`,
            }}
          />
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: "from" }}
          ></TweenOne>
          <div className="relative">
            <div className=" text-right bg-black opacity-75 lg:mt-80 md:mt-80 sm:mt-20 xs:mt-20 py-10 h-full w-full pr-20">
              <h1 className="lg:text-5xl md:text-4xl sm:text-3xl xs:text-3xl text-white font-bold lg:py-8 md:py-8 sm:p-0 xs:p-0">
                A Green Ethuos
              </h1>
              <p className="lg:text-xl md:text-xl sm:text-xl xs:text-lg ">
                Your trusted partner in agro industires
              </p>
            </div>
            <button className="absolute top-16 left-20 z-30 px-10 py-5 bg-gradient-to-r from-[#6CBD41] to-[#27AC45] rounded-md cursor-pointer text-white mt-5">
              Talk to us
            </button>
          </div>

          <TweenOne
            className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: "from", delay: 200 }}
          ></TweenOne>
        </Element>
        <Element prefixCls="banner-user-elem" key="1">
          <BgElement
            key="bg"
            className="bg bg-center bg-cover"
            style={{
              backgroundImage: `url("https://ik.imagekit.io/txh5evivcha5/pclstatic/187_TkjNPq8k5K.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676779332834")`,
            }}
          />
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: "from" }}
          ></TweenOne>
          <div className="relative">
            <div className=" text-left bg-black opacity-75 lg:mt-80 md:mt-80 sm:mt-32 xs:mt-20 py-10 h-full w-full pl-20">
              <h1 className="lg:text-5xl md:text-4xl sm:text-3xl xs:text-3xl text-white font-bold lg:py-8 md:py-8 sm:p-0 xs:p-0">
                Farmer's Dream Our Passion
              </h1>
              <p className="lg:text-xl md:text-xl sm:text-xl xs:text-lg ">
                Petrochem Helping yours idea behind
              </p>
            </div>
            <button className="absolute top-16 right-20 z-30 px-10 py-5 bg-gradient-to-r from-[#6CBD41] to-[#27AC45] rounded-md cursor-pointer text-white mt-5">
              Talk to us
            </button>
          </div>

          <TweenOne
            className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: "from", delay: 200 }}
          ></TweenOne>
        </Element>
      </BannerAnim>
    );
  }
}

export default Anmista;
