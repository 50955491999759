// OurTeam.js
import React from "react";
import HeaderBreadCrumb from "../components/article/HeaderBreadCrumb";
import Ourteamcard from "../components/cards/Ourteamcard";
import { ourteam } from "../data/contentdata";

const OurTeam = () => {
  return (
    <div>
      <HeaderBreadCrumb
        url="https://ik.imagekit.io/txh5evivcha5/pclstatic/opp_wGnUd5gCK.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675244270963"
        title="Our team"
        link="/"
        breadCrubName="Home"
      />
      <div className="w-full py-10 bg-white">
        <div className="p-5 lg:flex md:flex sm:block xs:block justify-around">
          <h1 className="text-4xl lg:w-[30%] md:w-[30%] mt-20 sm:w-full xs:w-full lg:block md:block sm:text-center xs:text-center font-extrabold p-5 text-[#383838] relative before:content-[''] before:absolute before:w-[150px] before:h-1 before:bg-green-600 before:top-1 before:left-1/2 before:-translate-x-1/2">
            We always work with a great team.
          </h1>
          <div className="flex lex-wrap">
            <Ourteamcard
              name={ourteam.chairman.name}
              designation={ourteam.chairman.designation}
              photo={ourteam.chairman.photo}
              src={ourteam.chairman.link}
            />
            <Ourteamcard
              name={ourteam.vicechairman.name}
              designation={ourteam.vicechairman.designation}
              photo={ourteam.vicechairman.photo}
              src={ourteam.vicechairman.link}
            />
          </div>
        </div>
        <div className="flex flex-wrap ">
          <Ourteamcard
            name={ourteam.managingdirector.name}
            designation={ourteam.managingdirector.designation}
            photo={ourteam.managingdirector.photo}
            src={ourteam.managingdirector.link}
          />
          <Ourteamcard
            name={ourteam.gmmarketing.name}
            designation={ourteam.gmmarketing.designation}
            photo={ourteam.gmmarketing.photo}
            src={ourteam.gmmarketing.link}
          />

          {/* <Ourteamcard
    // name={ourteam.gmfinance.name}
    // designation={ourteam.gmfinance.designation}
    // photo={ourteam.gmfinance.photo}
    // src={ourteam.gmfinance.link}
/> */}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
