import React from "react";
import Basicflaks from "../components/flacks/Basicflaks";
import { basicflex } from "../data/contentdata";
import VerticalTabComponent from "../components/flacks/VericalTabComponent";
import LargeImagebox from "../components/flacks/LargeImagebox";
import SectorFlaks from "../components/flacks/SectorFlaks";
import NewsCsr from "../components/csrnews/NewsCsr";
const CRS = () => {
  return (
    <div className="bg-white">
      <Basicflaks
        title={basicflex.data1.title}
        message={basicflex.data1.message}
      />
      <NewsCsr/>
      <LargeImagebox />
      <SectorFlaks />
      <VerticalTabComponent />
    </div>
  );
};

export default CRS;
