import React from "react";

const Offer = () => {
  const processes = [
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Picture%201_h8ny5bfMc.png?updatedAt=1716049695488",
      title: "Certified Boilers",
      description:
        "Our Boilers are Government of Bangladesh Inspection Authority and ISO certified. All the certified boilers have been tested and certified in the country as well as in the Australian market.",
    },
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Picture%202_I9WZ8fege.png?updatedAt=1716049695580",
      title: "Quality and Reliability",
      description:
        "We are always aware about improving the boiler’s quality and reliability, it is vital to consider its unique features in order to develop a plan of action to improve safety and security.",
    },
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Picture%203_OM4Vv5IovJ.png?updatedAt=1716049695584",
      title: "Technologies and Innovation",
      description:
        "We are the leader in terms of quality, innovation and the latest technology. Always we ensure the recent technologies and Inventions when we manufacture boilers. Our high quality boiler, Comparable to any other foreign origin.",
    },
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Picture%204_KWopboF_s.png?updatedAt=1716049695485",
      title: "Wide range of solutions",
      description:
        "We provide engineering solutions and consultation services for all of these engineering products, including boilers, pumps, burners, garment finishing equipment, waste heat recovery systems,waste heat recovery systems, HYPN (Booster) systems, and fire fighting systems.",
    },
  ];

  return (
    <div className="bg-white py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-red-500 font-semibold tracking-wide uppercase">
            Working Steps
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-cyan-700 sm:text-4xl">
            What We Offer
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {processes.map((process, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex items-center justify-center mb-4">
                <img
                  className="h-12 w-12"
                  src={process.icon}
                  alt={process.title}
                />
              </div>
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-900">
                  {process.title}
                </h3>
                <p className="mt-2 text-base text-gray-500">
                  {process.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-8 flex justify-center items-center bg-cyan-900">
          <div className="flex items-center">
            <svg
              className="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <p className="ml-2 text-base text-white w-96 p-6">
              We are one of the highest graded company in United State. For any
              Kind of help please contact our 24/7 hotline call services. we are
              ready for you
            </p>
          </div>
          <div className="ml-4">
            <a
              href="tel:+880 248961701-3"
              className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              +880 248961701-3
            </a>
            <p className="mt-2 text-base text-white">Have any Questions?</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
