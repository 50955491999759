import React from "react";
import ConcersDetails from "../components/article/ConcersDetails";
import { sisterconcernsdetails } from "../data/contentdata";

const Petroproduct = () => {
  return (
    <div className="bg-white">
      <ConcersDetails
        logo={sisterconcernsdetails.petroproduct.logo}
        summary={sisterconcernsdetails.petroproduct.summary}
        photo={sisterconcernsdetails.petroproduct.photo}
        mission={sisterconcernsdetails.petroproduct.mision}
        vision={sisterconcernsdetails.petroproduct.vision}
        tagline={sisterconcernsdetails.petroproduct.tagline}
        name={sisterconcernsdetails.petroproduct.name}
        
      />
    </div>
  );
};

export default Petroproduct;
