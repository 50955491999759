import React from "react";
import { FaAngleRight } from "react-icons/fa";
const RoundCorrectIcon = () => {
  return (
    <div className="rounded-full bg-gradient-to-r from-[#0b9965] to-[#00d084] h-6 w-6 flex justify-center items-center text-white mr-2 p-2">
      <FaAngleRight />
    </div>
  );
};

export default RoundCorrectIcon;
