import React from "react";
import { FaAward, FaHistory, FaHubspot } from "react-icons/fa";
import Cmessage from "../components/article/Cmessage";
import HeaderBreadCrumb from "../components/article/HeaderBreadCrumb";
import HistoryTab from "../components/article/HistoryTab";
import Ourfacilities from "../components/article/Ourfacilities";
import Flipcard from "../components/cards/Flipcard";
import MultipleItemSlider from "../components/slider/Multislider";
import { flicpcard } from "../data/contentdata";

const AboutUs = () => {
  return (
    <div className="overflow-x-hidden bg-white">
      <HeaderBreadCrumb
        url="https://ik.imagekit.io/txh5evivcha5/pclstatic/bgteam_zjQadAwp1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675239114159"
        title="About Us"
        link="/"
        breadCrubName="Home"
      />
      <Cmessage />
      {/* flipcard starts here  */}
      <div className="flex justify-center">
        <div className="flex p-10 gap-5 m-5 flex-wrap">
          <Flipcard
            icon=<FaHistory className="inline-block text-4xl my-3" />
            title={flicpcard.data1.title}
            subtitle={flicpcard.data1.subtitle}
            description={flicpcard.data1.description}
          />
          <Flipcard
            icon=<FaAward className="inline-block text-4xl my-3" />
            title={flicpcard.data2.title}
            subtitle={flicpcard.data2.subtitle}
            description={flicpcard.data2.description}
          />
          <Flipcard
            icon=<FaHubspot className="inline-block text-4xl my-3" />
            title={flicpcard.data3.title}
            subtitle={flicpcard.data3.subtitle}
            description={flicpcard.data3.description}
          />
        </div>
      </div>
      {/* flipcard Ended here  */}

      <Ourfacilities />

      <HistoryTab />

      <div className=" py-20">
        <MultipleItemSlider />
      </div>
    </div>
  );
};

export default AboutUs;
