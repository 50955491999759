import React from "react";

const ConcersDetails = ({logo, summary, extrasummary,photo,mission,vision,name,tagline, m, v}) => {
  return (
    <div className="w-full font-[Poppins] p-20 text-gray-500">
      <div className="text-center">
        <img
          className="inline-block w-28"
          src={logo}
          alt=""
        />
        <h1 className="text-4xl font-bold uppercase text-[#383838] py-3">
          {name}
        </h1>
        <p className="text-lg text-[#38383898] pb-5">
          {tagline}
        </p>
      </div>

      <div className="lg:flex md:flex sm:block xs:block py-10 gap-8 lg:px-32 md:px-20 sm:px-12 xs:px-6 ">
        <div className="lg:w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%] h-80 py-5 ">
          <img
            
            src={photo}
            alt=""
            className="object-cover w-[100%] h-[100%]"
          />
        </div>
        <div className="lg:w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%] py-5">
          <p>{summary}</p>
        </div>
      </div>
<hr />
    <div>
        <p className="py-10 lg:px-32 md:px-20 sm:px-12 xs:px-6 ">{extrasummary}</p>
    </div>
      <hr />
      <div className="lg:flex md:flex sm:block xs:block  lg:px-32 md:px-20 sm:px-12 xs:px-6 gap-8">
        <div className="lg:w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%] py-10">
          <h1 className="text-2xl font-semibold py-2">{m}</h1>
          <p>{mission}</p>
        </div>
        <div className="lg:w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%] py-10">
          <h1 className="text-2xl font-semibold py-2">{v}</h1>
          <p>{vision}</p>
        </div>
      </div>
    </div>
  );
};

export default ConcersDetails;
