import React from "react";

import { corpcarddata } from "../../data/contentdata";
const Corpcard = () => {
  return (
    <div className="flex gap-6  justify-center lg:m-auto flex-wrap">
      {corpcarddata.map((item) => (
        <div className=" lg:m-0 md:m-0 sm:m-5 xs:m-5 font-[Poppins] relative w-72 h-w-72 shadow-lg hover:shadow-2xl rounded-lg hover:bg-gradient-to-r from-[#6CBD41] to-[#27AC45] bg-white transition ease-in-out duration-300 hover:text-white text-[#383838] hover:transition hover:ease-in-out hover:duration-300 hover:before:content-[''] hover:before:absolute hover:before:left-3 hover:before:bg-gradient-to-r from-[#74c947] to-[#2cc550]  hover:before:top-3 hover:before:w-[100%] hover:before:h-[100%] hover:before:rounded-xl hover:before:z-[-1] hover:before:opacity-50  z-10 hover:before:duration-300">
          <div className="text-center py-5 px-5 ">
            {item.icon}
            <h3 className="text-xl font-bold py-3">{item.title}</h3>
            <p className="py-2">
              {item.details}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Corpcard;
