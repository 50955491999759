import React from "react";
import { Link } from "react-router-dom";
import ConcersDetails from "../components/article/ConcersDetails";
import { sisterconcernsdetails } from "../data/contentdata";
const Spl = () => {
  return (
    <div className="bg-white">
      <ConcersDetails
        logo={sisterconcernsdetails.spl.logo}
        summary={sisterconcernsdetails.spl.summary}
        extrasummary={sisterconcernsdetails.spl.extrasummary}
        photo={sisterconcernsdetails.spl.photo}
        mission={sisterconcernsdetails.spl.mision}
        vision={sisterconcernsdetails.spl.vision}
        tagline={sisterconcernsdetails.spl.tagline}
        name={sisterconcernsdetails.spl.name}
        m="Mission"
        v="Vision"
      />

      <div className="flex justify-center py-5 bg-green-400 hover:bg-green-600 hover:cursor-pointer transition duration-300">
        <Link to="http://solarpowerbd.com/" className="text-white text-xl"> Visit Solar Power </Link>
      </div>
    </div>
  );
};

export default Spl;
