import React from "react";
import ConcersDetails from "../components/article/ConcersDetails";
import { sisterconcernsdetails } from "../data/contentdata";

const Pcl = () => {
  return (
    <div className="bg-white">
      <ConcersDetails
        logo={sisterconcernsdetails.pcl.logo}
        summary={sisterconcernsdetails.pcl.summary}
        extrasummary={sisterconcernsdetails.pcl.extrasummary}
        photo={sisterconcernsdetails.pcl.photo}
        mission={sisterconcernsdetails.pcl.mision}
        vision={sisterconcernsdetails.pcl.vision}
        tagline={sisterconcernsdetails.pcl.tagline}
        name={sisterconcernsdetails.pcl.name}
       
      />
    </div>
  );
};

export default Pcl;
