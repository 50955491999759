import React, { useState, useEffect } from 'react';
import Menubtn from './Menubtn';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';
import logo from "../../asset/logos/logo_main.png";

function Navbar() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [scrolled,setScrolled]=React.useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

  
    const onMouseEnter = () => {
      if (window.innerWidth < 960) {
        setDropdown(false);
      } else {
        setDropdown(true);
      }
    };
  
    const onMouseLeave = () => {
      if (window.innerWidth < 960) {
        setDropdown(false);
      } else {
        setDropdown(false);
      }
    };

    const handleScroll=() => {
      const offset=window.scrollY;
      if(offset > 200 ){
        setScrolled(true);
      }
      else{
        setScrolled(false);
      }
    }
  
    useEffect(() => {
      window.addEventListener('scroll',handleScroll)
    })

  let navbarClasses=['navbar'];
    if(scrolled){
      navbarClasses.push('scrolled');
    }
  
    return (
      <>
        <nav className={`${navbarClasses.join(" ")} z-50` }>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src={logo} alt="logo" className="md:cursor-pointer h-14 " />
           
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            
            <li
              className='nav-item'
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to='/whoweare'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Who we are <i className='fas fa-caret-down' />
              </Link>
              {dropdown && <Dropdown />}
            </li>
            <li className='nav-item'>
              <Link
                to='/sister-concern'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Sister Concern
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/sustainability'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Sustainability
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/career'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Career
              </Link>
            </li>
            <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Sign Up
              </Link>
            </li>
          </ul>
          <Menubtn btnname="Get Started" />
        </nav>
      </>
    );
  }

export default Navbar;