import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper/core";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);

const IslSlider = () => {
  const slideVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <Swiper
      spaceBetween={0}
      effect="fade"
      speed={1000}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      navigation
      className="hero-slider"
    >
      <SwiperSlide>
        <img
          src="https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Untitled%20design%20(4)_wYEMZPEOI.png?updatedAt=1716128904244"
          alt="Slider 1"
          className="w-full h-[600px] object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
          <motion.h2
            className="text-white text-4xl font-bold mb-4"
            variants={slideVariants}
            initial="hidden"
            animate="visible"
          >
            Discover Our Latest Innovations
          </motion.h2>
          <Link to="http://www.internationalserviceslimited.com ">
            <motion.button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              variants={slideVariants}
              initial="hidden"
              animate="visible"
            >
              See Details
            </motion.button>
          </Link>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Untitled%20design%20(2)_HXoWHtQzX.png?updatedAt=1716128871829"
          alt="Slider 2"
          className="w-full h-[600px] object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
          <motion.h2
            className="text-white text-4xl font-bold mb-4"
            variants={slideVariants}
            initial="hidden"
            animate="visible"
          >
            Explore Our Premium Product Range
          </motion.h2>

          <Link to="http://www.internationalserviceslimited.com ">
            <motion.button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              variants={slideVariants}
              initial="hidden"
              animate="visible"
            >
              See Details
            </motion.button>
          </Link>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Untitled%20design%20(5)_CYpZeqKaQ.png?updatedAt=1716128921011"
          alt="Slider 3"
          className="w-full h-[600px] object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
          <motion.h2
            className="text-white text-4xl font-bold mb-4"
            variants={slideVariants}
            initial="hidden"
            animate="visible"
          >
            Experience Excellence in Engineering
          </motion.h2>

          <Link to="http://www.internationalserviceslimited.com ">
            <motion.button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              variants={slideVariants}
              initial="hidden"
              animate="visible"
            >
              See Details
            </motion.button>
          </Link>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Untitled%20design%20(3)_7M7Etf5XA.png?updatedAt=1716128889894"
          alt="Slider 4"
          className="w-full h-[600px] object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
          <motion.h2
            className="text-white text-4xl font-bold mb-4"
            variants={slideVariants}
            initial="hidden"
            animate="visible"
          >
            Transforming Industries with Cutting-Edge Solutions
          </motion.h2>
          <Link to="http://www.internationalserviceslimited.com ">
            <motion.button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              variants={slideVariants}
              initial="hidden"
              animate="visible"
            >
              See Details
            </motion.button>
          </Link>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default IslSlider;
