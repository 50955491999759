import React from "react";
import Teamdetails from "../components/article/Teamdetails";
import { ourteam } from "../data/contentdata";
const Vc = () => {
  return <div className="py-24 bg-white">
    <Teamdetails name={ourteam.vicechairman.name} designation={ourteam.vicechairman.designation} details={ourteam.vicechairman.shortsummary} photo={ourteam.vicechairman.photo}/>
  </div>;
};

export default Vc;
