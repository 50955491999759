import React from "react";
import RecruitmentCard from "../cards/RecruitmentCard";
import Accordion from "../flacks/Accordion";
import Videos from "../flacks/Videos";
const panels = [
  {
    title: "Notice",
    content:
      "THIS APPLICATION IS NOT AN EMPLOYMENT CONTRACT but merely is intended to evaluate suitability for employment. It is the policy of the company to provide equal employment to all qualified persons without discrimination on the basis of sex, race, color, religion, age, national origin, citizenship, disability, veteran status, or any other status protected under local, state or federal law. It is also the policy of the company to have the option of conducting pre-employment screening before a job offer is made. If a job offer is made, employment may be contingent upon the successful completion of a pre-employment drug screening and/or medical examination. This application will remain active for 3 years.",
    isOpen: false,
  },
];

const CareerDetails = () => {
  return (
    <div className="bg-white text-gray-500">
      <div className="lg:p-16 md:p-10 sm:p-6 xs:p-4 lg:flex md:flex sm:block xs:block">
        <div className="lg:w-[45%] md:w-[45%] sm-[100%] xs-[100%] p-10">
          <p className="py-3 font-semibold text-green-[#383838] relative after:content-[''] after:w-20 after:bg-green-600 after:top-[-8px] after:left-0 after:h-1 after:absolute">
            Career
          </p>
          <h1 className="text-4xl font-extrabold text-[#383838]">
            We are hiring. Talents are welcome
          </h1>
        </div>
        <div className="lg:w-[55%] md:w-[55%] sm-[100%] xs-[100%] lg:px-10 md:px-8 sm:px-4 xs:px-2">
          <p className="leading-[35px] text-lg font-[Poppins] text-[#383838]">
            We are constantly looking for professionals who befit the culture of
            our organization and have an undying spirit to reach new heights in
            their professional careers. We stand by the values that made us who
            we are today. They’ve shaped our culture, our work ethics, and our
            decisions; helping us push the envelope and be more than what we
            were yesterday.
          </p>
        </div>
      </div>

      <div className="flex mx-20 justify-center my-6">
        <Videos />
      </div>
      <div className="bg-gradient-to-r from-[#6CBD41] to-[#27AC45] mx-28 p-10  justify-center rounded-md">
        <h1 className="text-white text-2xl font-semibold ">
          SEND YOUR CV TODAY{" "}
        </h1>
        <div className="h-[3px] bg-white w-full my-3"></div>
        <h3 className="text-xl font-bold text-white">
          e-mail us at : monia@petrochembd.com{" "}
        </h3>
        <h3 className="text-xl font-bold text-white">
          {" "}
          Call : 88 - 0248961701-3
        </h3>
      </div>

      <Accordion panels={panels} />

      <div className="bg-gradient-to-r from-[#6CBD41] to-[#27AC45] mx-28 p-10 flex justify-center rounded-md">
        <h1 className="text-white text-2xl font-semibold">
          Our On Going Recruitment
        </h1>
      </div>
      <RecruitmentCard />
    </div>
  );
};

export default CareerDetails;
