import React from 'react'

const PartnerTitle = ({partnerType}) => {
  return (
    <>
    <h1 className="ml-12 font-semibold text-2xl mt-10 py-2 px-2 text-gray-600">{partnerType}</h1>
    <div className="w-20 h-1 bg-gradient-to-r from-[#0b9965] to-[#00d084] ml-16 mb-6"></div>
       
    </>
  )
}

export default PartnerTitle