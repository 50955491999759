import React from "react";
import AboutCompany from "../components/islcomponents/AboutCompany";
import Offer from "../components/islcomponents/Offer";
import Services from "../components/islcomponents/Services";
import Statistics from "../components/islcomponents/Statistics";
import IslClientsSlider from "../components/slider/IslClientsSlider";
import IslSlider from "../components/slider/IslSlider";
const Isl = () => {
  return (
    <div className="bg-white">
      <IslSlider />

      <div className="py-10">
        <AboutCompany />
        <Services />
        <Offer />

        <Statistics />
        <div className="py-10">
          <IslClientsSlider />
        </div>
      </div>
    </div>
  );
};

export default Isl;
