import React from "react";
import { ourteam } from "../../data/contentdata";
import {
  FaAsterisk,
  FaEnvelope,
  FaMapMarkerAlt,
  FaLessThan,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import MultipleItemSlider from "../slider/Multislider";
const Teamdetails = ({ name, designation, details, photo }) => {
  return (
    <div className="lg:px-48 md:px-36 sm:px-24 xs:px-12 ">
      <div className="flex items-center text-white bg-green-600 w-24 p-2 justify-center rounded-lg shadow-lg">
        <FaLessThan className="pr-2" />
        <Link to="/our-team">Back</Link>
      </div>
      <div
        className="bg-center bg-no-repeat bg-[#EAEAEA] md:flex sm:block xs:block lg:flex  bg-cover w-[100%] flex my-10 rounded-lg shadow-lg"
        style={{
          backgroundImage:
            "url('https://ik.imagekit.io/txh5evivcha5/pclstatic/bg-team-details_szL8kvG7ex.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676454257771')",
        }}
      >
        <div className="w-96 ">
          <img className="h-[390px] object-cover" src={photo} alt="imagesj" />
        </div>
        <div>
          <div className="flex p-10">
            <div className="bg-gray-400 w-[20%] lg:h-[25%px] md:h-[25%px] rounded-l-lg flex justify-center items-center">
              <FaAsterisk className="text-4xl" />
            </div>
            <div className="bg-black rounded-r-lg h-[25%] w-[70%]  text-white p-8">
              <p className="py-1 text-2xl font-bold">{name}</p>
              <p className="py-1 text-[#c5c5c5]">{designation}</p>
            </div>
          </div>
          <div className="pl-10">
            <p className="text-[#383838] font-semibold text-lg py-2 flex items-center">
              <span className="px-2">
                <FaEnvelope />
              </span>
              {ourteam.chairman.email}
            </p>
            <p className="w-[540px] pr-20 font-semibold items-center flex text-[#383838] py-2">
              <span className="px-2">
                <FaMapMarkerAlt />
              </span>
              {ourteam.chairman.address}
            </p>
          </div>
        </div>
      </div>

      <div>
        <p className="lg:px-20 md:px-12 sm:px-10 xs:px-6 text-gray-500">{details}</p>
      </div>

      <div className="py-20">
        <MultipleItemSlider />
      </div>
    </div>
  );
};

export default Teamdetails;
