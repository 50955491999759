import React from "react";
import "./flipcard.css";

function Flipcard({ icon, title, subtitle, description }) {
  return (
    <div class="flip-card w-[380px] h-[320px] bg-transparent">
      <div class="flip-card-inner rounded-md  ">
        <div class="flip-card-front bg-[#8A35FF] text-white rounded-md flex justify-center items-center ">
          <div className="text-center">
            {icon}
            <h1 className="text-4xl font-semibold">{title}</h1>
          </div>
        </div>
        <div class="flip-card-back  bg-[#502fc6] text-white rounded-md flex justify-center items-center">
          <div>
            <h1 className="text-3xl py-3">{subtitle}</h1>
            <p className="p-3">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Flipcard;
