import React from "react";
// import FacilityCard from "../cards/FacilityCard";

import { facilitydetails } from "../../data/contentdata";
const Ourfacilities = () => {
  return (
    <div>
      <h1 className="font-bold text-4xl text-center py-10 text-gray-600">Our Facilities</h1>

      <div className="bg-[#F2F2F2] flex p-20 gap-5 w-[100%] flex-wrap justify-center">
        {facilitydetails.map((facility) => (
          <div
            key={facility.id}
            className="hover:shadow-none transform hover:translate-y-5 hover:duration-300 transition ease-in-out  shadow-lg rounded-md bg-white my-10 py-10 w-[280px] h-[190px] flex justify-center flex-col items-center relative before:content-[''] before:bg-green-500 before:w-[0%] before:h-1 before:hover:w-[100%] before:hover:bottom-0 before:hover:absolute before:hover:left-0 before:hover:transition before:hover:duration-300 before:hover:ease-out"
          >
            <div className="flex text-4xl bg-green-600 rounded-full p-10 absolute top-[-50px] text-white">
            {facility.icon}
            </div>
            <div className="uppercase font-bold text-gray-800 text-xl p-5 text-center">
              {facility.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ourfacilities;
