import React from "react";
import CountUp, { useCountUp } from "react-countup";
import BeatButton from "../flacks/BeatButton";

const States = () => {
  useCountUp({
    ref: "counter",
    end: 1234567,
    enableScrollSpy: true,
    scrollSpyDelay: 1000,
  });

  return (
    <div
      className="bg-no-repeat bg-cover bg-fixed bg-center max-w-[100%] z-[-10] font-[Poppins] "
      style={{
        backgroundImage:
          "url('https://ik.imagekit.io/txh5evivcha5/pclstatic/gettyimages-1171059428-2048x2048-1_nTBMsFq7b.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676779337192')",
      }}
    >
      <div className="w-[100%] h-[100%] bg-[#000000b6] z-10 py-20">
        <div className="lg:flex md:flex sm:block xs:block w-full py-10">
          <div className="grid p-10">
            <div>
              <h1 className="text-white text-4xl font-bold py-10">
                পেট্রোকেম বাংলার কৃষকের গর্ব
              </h1>
              <p className="text-white">
                পেট্রোকেম বাংলাদেশ লিমিটেড বাংলাদেশের অন্যতম শীর্ষস্থানীয়
                এগ্রোকেমিক্যাল কোম্পানি
              </p>

              <BeatButton/>
              
            </div>
          </div>

          <div className="grid  place-items-center ">
            <div className="m-3">
              <div className="flex gap-5 ">
                <div className="text-white hover:bg-[#6cbd41c7]  hover:skew-y-3 transition duration-300 ease-in-out w-52 h-52 bg-gray-800 rounded-full flex justify-center items-center ')">
                  <div className="p-10 text-center">
                    <CountUp
                      start={500}
                      end={20000}
                      duration={2}
                      enableScrollSpy
                      suffix="+"
                      className="text-2xl font-bold "
                    />
                    <p>Team Members</p>
                  </div>
                </div>
                <div className="text-white hover:bg-[#6cbd41c7] hover:skew-y-3 transition duration-300 ease-in-out w-52 h-52 bg-gray-800 rounded-full flex justify-center items-center ">
                  <div className="p-10 text-center">
                    <CountUp
                      start={100}
                      end={500}
                      duration={2}
                      enableScrollSpy
                      suffix="+"
                      className="text-2xl font-bold "
                    />
                    <p>Farmers</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex gap-5">
                <div className="text-white hover:bg-[#6cbd41c7] hover:skew-y-3 transition duration-300 ease-in-out w-52 h-52 bg-gray-800 rounded-full flex justify-center items-center ">
                  <div className="p-10 text-center">
                    <CountUp
                      start={0}
                      end={30}
                      duration={2}
                      enableScrollSpy
                      suffix="+"
                      className="text-2xl font-bold "
                    />
                    <p>Winning Awards</p>
                  </div>
                </div>
                <div className="text-white hover:bg-[#6cbd41c7] hover:skew-y-3 transition duration-300 ease-in-out w-52 h-52 bg-gray-800 rounded-full flex justify-center items-center ">
                  <div className="p-10 text-center">
                    <CountUp
                      start={0}
                      end={100}
                      duration={2}
                      enableScrollSpy
                      suffix="+"
                      className="text-2xl font-bold "
                    />
                    <p>Partners</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default States;
