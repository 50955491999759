import React from "react";
import { pclmap } from "../../data/contentdata";

const Pclmap = () => {
  return (
    <div
      className="bg-white bg-no-repeat bg-contain max-w-[100%] space-x-3 py-20 px-20 font-[Poppins]"
      style={{
        backgroundImage:
          "url('https://ik.imagekit.io/txh5evivcha5/pclstatic/bg-section-01_GFrACAw8Q.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676779336957')",
      }}
    >
      <div className="lg:flex md:flex sm:block xs:block w-full font-[Poppins]">
        <div className="grid lg:w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%]">
          <div>
            <h1 className="text-4xl text-gray-600 font-bold">
              {pclmap.data.title}
            </h1>
            <p className="py-10 text-gray-500">{pclmap.data.desc}</p>
          </div>

          <div className="flex gap-5">
            <div>
              <h1 className="text-2xl text-gray-600 font-bold py-2">
                {pclmap.data.zonecoverage}
              </h1>
              <p className="text-zinc-500">{pclmap.data.zonecoveragedesc}</p>
            </div>
            <div>
              <h1 className="text-2xl text-gray-600 font-bold py-2">
                {pclmap.data.satisfiction}
              </h1>
              <p className="text-zinc-500">{pclmap.data.satisdesc}</p>
            </div>
          </div>
        </div>

        <div className="grid lg:w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%]">
          <img
            className="w-[100%]"
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/Map-01-1422x1536_3v4uMxdF1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676779338396"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Pclmap;
