import React from "react";
import ConcersDetails from "../components/article/ConcersDetails";
import { sisterconcernsdetails } from "../data/contentdata";
const Pal = () => {
  return (
    <div className="bg-white">
      <ConcersDetails
        logo={sisterconcernsdetails.pai.logo}
        summary={sisterconcernsdetails.pai.summary}
        extrasummary={sisterconcernsdetails.pai.extrasummary}
        photo={sisterconcernsdetails.pai.photo}
        mission={sisterconcernsdetails.pai.mision}
        vision={sisterconcernsdetails.pai.vision}
        tagline={sisterconcernsdetails.pai.tagline}
        name={sisterconcernsdetails.pai.name}
      />
    </div>
  );
};

export default Pal;
