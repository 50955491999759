export const MenuItems = [
  {
    title: 'About us ',
    path:"/about-us",
    cName: 'dropdown-link'
  },
  {
    title: 'Our Team ',
    path: '/our-team',
    cName: 'dropdown-link'
  },
  {
    title: 'Mission & Vision',
    path: '/mission',
    cName: 'dropdown-link'
  },
  {
    title: 'CSR',
    path: '/csr',
    cName: 'dropdown-link'
  },
  {
    title: 'Partners',
    path: '/our-partners',
    cName: 'dropdown-link'
  }
];