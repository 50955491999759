import React from "react";
import { coreValue } from "../../data/contentdata";
const Corevalue = () => {
  return (
    <div className="py-10 bg-white">
      <h1 className="text-3xl font-bold text-center text-gray-600">
        Our Core Values
      </h1>

      <div className="flex p-16 flex-wrap">
        {coreValue.map((cvalue) => (
          <div key={cvalue.id} className="mx-5 my-5">
            <img src={cvalue.imagesrc} className="h-32 mx-auto" alt="" />
            <h1 className="text-3xl font-bold text-center py-4 text-gray-500">
              {cvalue.title}
            </h1>
            <p className="text-center text-gray-500 w-72">{cvalue.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Corevalue;
