import React from "react";
import ConcersDetails from "../components/article/ConcersDetails";
import { sisterconcernsdetails } from "../data/contentdata";
const Banglasolarit = () => {
  return (
    <div className="bg-white">
      <ConcersDetails
        logo={sisterconcernsdetails.banglasolar.logo}
        summary={sisterconcernsdetails.banglasolar.summary}
        photo={sisterconcernsdetails.banglasolar.photo}
        mission={sisterconcernsdetails.banglasolar.mision}
        vision={sisterconcernsdetails.banglasolar.vision}
        tagline={sisterconcernsdetails.banglasolar.tagline}
        name={sisterconcernsdetails.banglasolar.name}
     
      />
    </div>
  );
};

export default Banglasolarit;
