import React from "react";
import Dslider from "../slider/Dslider";
const Testimonial = () => {
  return (
    <div
      className="bg-no-repeat bg-center max-w-[100%] bg-black space-x-3 py-20 font-[Poppins]"
      style={{
        backgroundImage:
          "url('https://ik.imagekit.io/txh5evivcha5/pclstatic/bg-section-02_hFSaDNsI8.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676779335752')",
      }}
    >
      <div className="px-20 md:px-20 sm:px-10 xs:px-4 lg:flex md:flex sm:block xs:block m-auto">
        <h1 className="relative py-10 lg:px-20 md:px-10 sm:px-4 xs:px-2 text-white font-bold text-4xl before:content-[''] before:w-[50px] before:h-1 before:bg-green-600 before:absolute before:left-0 before:top-[20px]">
          To review means to look back over something for evaluation or memory.
        </h1>
        <p className="p-10 text-white text-lg lg:px-20 md:px-10 sm:px-4 xs:px-2">
          It’s always a joy to hear that the work I do has positively impacted
          our clients and that they are happy to share their experience.
        </p>
      </div>

      <div>
        <Dslider/>
      </div>
    </div>
  );
};

export default Testimonial;
