import React from "react";
import ConcersDetails from "../components/article/ConcersDetails";
import { sisterconcernsdetails } from "../data/contentdata";
const Habitate = () => {
  return (
    <div className="bg-white">
      <ConcersDetails
        logo={sisterconcernsdetails.habitate.logo}
        summary={sisterconcernsdetails.habitate.summary}
        photo={sisterconcernsdetails.habitate.photo}
        tagline={sisterconcernsdetails.habitate.tagline}
        name={sisterconcernsdetails.habitate.name}
      
      />
    </div>
  );
};

export default Habitate;
