import React from "react";
import { newslink } from "../../data/csrnewsdata";
import { Link } from "react-router-dom";
const NewsLink = () => {
  return (
    <div className="mx-10 my-10 flex justify-center flex-wrap">
      {newslink.news.map((data) => (
        <div className=" h-auto w-96 bg-gray-100 shadow-md p-3 mx-3 my-5">
            <img src={data.imagesrc} alt="photosds" />
            <h2 className="text-lg text-center font-bold p-2 text-gray-500">{data.title}</h2>
            <p className="text-center my-5 text-gray-500">{data.details}</p>
            <Link to={data.url} className="bg-green-600 p-3 text-white rounded-md block text-center hover:bg-green-700"> Read more</Link>

        </div>
      ))}
    </div>
  );
};

export default NewsLink;
