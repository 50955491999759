import VerticalTabs from "./VerticalTabs";
import { tabData } from "../../data/contentdata";

  
  function VerticalTabComponent() {
    return (
      <div>
        <VerticalTabs tabs={tabData} />
      </div>
    );
  }

  
export default VerticalTabComponent