import React from "react";
import Teamdetails from "../components/article/Teamdetails";
import { ourteam } from "../data/contentdata";
const Gmf = () => {
  return <div className="py-24 bg-white">
    <Teamdetails name={ourteam.gmfinance.name} designation={ourteam.gmfinance.designation} details={ourteam.gmfinance.shortsummary} photo={ourteam.gmfinance.photo}/>
  </div>;
};

export default Gmf;
