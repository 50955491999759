import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { logoSlider } from "../../data/contentdata";
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000
};

function MultipleItemSlider() {
  const renderSlides = () => {
    return logoSlider.map(item => (
      <div key={item} className="p-5 max-w-[100%] ">
        
        <img src={item.src} alt="logo" className="grayscale hover:grayscale-0 transition ease-in-out duration-150" />
      </div>
    ));
  };

  return (
    <Slider {...settings}>
      {renderSlides()}
    </Slider>
  );
}

export default MultipleItemSlider;
