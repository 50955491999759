import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import HeaderBreadCrumb from "../components/article/HeaderBreadCrumb";
import { FaMapMarkedAlt, FaEnvelopeOpenText, FaPhoneAlt } from "react-icons/fa";
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9t3wusp",
        "template_3kza3ri",
        form.current,
        "zxax3e-Lx0w3s1w-g"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="bg-[#EFF1FF] font-[Poppins]">
      <HeaderBreadCrumb
        url="https://ik.imagekit.io/txh5evivcha5/pclstatic/chris-montgomery-smgTvepind4-unsplash_WBMzthjBj.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677403227780"
        title="Contact Us"
        link="/"
        breadCrubName="home "
      />

      <div className="lg:p-20 md:p-20 sm:p-10 xs:p-4 lg:flex md:flex sm:block xs:block font=[Poppins]">
        <h1 className="lg:text-4xl md:text-3xl sm:text-2xl xs:text-2xl font-bold text-black py-5">
          If need any info please contact{" "}
          <span className="text-green-600">us!</span>{" "}
        </h1>
        <p className="lg:p-20 md:p-20 sm:p-10 xs:p-4 text-lg ">
          We’re glad to discuss your organisation’s situation. So please contact
          us via the details below, or enter your request.
        </p>
      </div>

      <div className="lg:flex md:flex sm:block xs:block">
        <div className="lg:w-[50%] md:w-[50%] sm:w-full xs:w-full p-20">
          <img
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/2448112_nZmIaHQMB8.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677473667029"
            alt=""
            className="w-full"
          />
        </div>
        <div className="lg:w-[50%] md:w-[50%] sm:w-full xs:w-full p-10">
          <h1 className="text-3xl font-bold py-5 text-gray-500">Contact US</h1>
          <form ref={form} onSubmit={sendEmail}>
            <label className="mt-2 inline-block font-semibold">Full Name</label>
            <input
              name="user_name"
              type="text"
              required
              placeholder="Enter your full name "
              className="input input-bordered input-success w-full bg-white"
            />
            <label className="mt-2 inline-block font-semibold">
              Phone No:{" "}
            </label>
            <input
              name="user_phone"
              type="number"
              required
              placeholder="Phone Number"
              className="input input-bordered input-success w-full  bg-white"
            />
            <label className="mt-3 inline-block font-semibold">Email</label>
            <input
              name="user_email"
              type="text"
              placeholder="Type your email"
              className="input input-bordered input-success w-full  bg-white"
            />
            <label className="mt-3 inline-block font-semibold">Message</label>
            <textarea
              name="message"
              className="textarea textarea-success w-[100%]  bg-white"
              placeholder="Bio"
            ></textarea>
            <input
              type="submit"
              value="Send"
              className="text-lg  bg-white font-semibold mt-5 px-10 py-3 bg-gradient-to-r from-[#6CBD41] to-[#27AC45] rounded-md cursor-pointer text-white w-[100%]"
            />
          </form>
        </div>
      </div>

      <hr className="py-10" />
      <div className="w-full lg:flex md:flex sm:block xs:block lg:p-20 md:p-16 sm:p-8 xs:p-4 text-center">
        <div className="lg:p-10 md:p-10 sm:p-6 xs:p-3 lg:w-[33%] md:w-[33%] sm:w-full xs:w-full ">
          <div className="flex items-center justify-center">
            <FaMapMarkedAlt className="mr-2 text-3xl text-[#27AC45]" />
            <h1 className=" text-2xl font-bold py-3 text-gray-500">Our Office Address</h1>
          </div>
          <p className="text-gray-500">
            ABC Heritage (3rd Floor),Plot No. 2 & 4, Jashim Uddin Avenue
            Sector-3, Uttara C/A Dhaka-1230, Bangladesh
          </p>
        </div>
        <div className="lg:p-10 md:p-10 sm:p-6 xs:p-3 lg:w-[33%] md:w-[33%] sm:w-full xs:w-full">
          <div className="flex items-center justify-center">
            <FaEnvelopeOpenText className="mr-2 text-3xl text-[#27AC45]" />
            <h1 className="text-2xl font-bold py-3 text-gray-500">Mail us:</h1>
          </div>
          <p className="text-gray-500">info@petrochembd.com</p>
        </div>
        <div className="lg:p-10 md:p-10 sm:p-6 xs:p-3 lg:w-[33%] md:w-[33%] sm:w-full xs:w-full">
          <div className="flex items-center justify-center">
            <FaPhoneAlt className="mr-2 text-3xl text-[#27AC45]" />
            <h1 className="text-2xl font-bold py-3 text-gray-500">Call for help:</h1>
          </div>
          <p className="text-gray-500">88-0248961701</p>
          <p className="text-gray-500"> 88-0248961702 </p>
          <p className="text-gray-500"> 88-0248961703</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
