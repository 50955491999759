import React from "react";

const Videos = () => {
  const pclVideo =
    "https://ik.imagekit.io/txh5evivcha5/pclstatic/pcl-career/VID-20231214-WA0004_mXM3LH4Bc.mp4?updatedAt=1702786108513";
  return (
    <div>
      <video
        src={pclVideo}
        height="100%"
        width="100%"
        controls={true}
        loop={true}
      />
    </div>
  );
};

export default Videos;
