import React from "react";
import HeaderBreadCrumb from "../components/article/HeaderBreadCrumb";
import Vision from "../components/article/Vision";
import Corevalue from "../components/article/Corevalue";
import RoundCorrectIcon from "../components/flacks/RoundCorrectIcon";
import { missionList } from "../data/contentdata";

const Mission = () => {
  const leftMission = missionList.slice(0, 5);
  const rightMission = missionList.slice(5);
  return (
    <div className="bg-white">
      <HeaderBreadCrumb
        url="https://ik.imagekit.io/txh5evivcha5/pclstatic/bg_factory_mission_NI-JtvDBud.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675242571043"
        title="Mission"
        link="/whoweare"
        breadCrubName="Who we are"
      />
      <Vision />
      <div className="py-20 bg-gray-100" >
        <h1 className="text-5xl font-bold text-gray-700 mx-16">Our Mission</h1>
        <div className="flex p-10 ">
          <div className="w-[50%] px-5 text-gray-500">
            {leftMission.map((list) => (
              <div className="my-4 flex "><RoundCorrectIcon className="ml-4"/>{list.mission}</div>
            ))}
          </div>
          <div className="w-[50%] px-5 text-gray-500">
            {rightMission.map((list) => (
              <div className="my-4 flex "><RoundCorrectIcon className="ml-4"/>{list.mission}</div>
            ))}
          </div>
        </div>
      </div>

      <Corevalue/>
    </div>
  );
};

export default Mission;
