import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { homecardslider } from "../../data/contentdata";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="h-10 w-10  bg-gray-400 absolute right-5 top-52 flex justify-center items-center rounded-full text-white opacity-50 hover:opacity-100"
      onClick={onClick}
    >
      <FaAngleRight />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="h-10 w-10  bg-gray-400 absolute left-5 top-52 flex justify-center items-center rounded-full text-white opacity-50 hover:opacity-100 z-10"
      onClick={onClick}
    >
      <FaAngleLeft />
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        arrows: false,
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        arrows: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 639,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};

function Sliderhome() {
  const renderSlides = () => {
    return homecardslider.map((item) => (
      <div key={item.id} className="p-2 hover:hidden">
        <div className="p-3 bg-white  rounded-md shadow-xl hover:shadow-2xl font-[Poppins] ">
          <div className="h-2 bg-gradient-to-r from-[#6CBD41] to-[#27AC45] w-[100%] "></div>

          <div className="flex justify-center py-10 text-5xl text-[#27AC45]">
            {item.src}
          </div>
          <h1 className="lg:text-2xl md:text-lg py-5 text-center font-bold text-[#383838]">
            {item.title}
          </h1>

          <div className="w-28 h-1 bg-gradient-to-r from-[#6CBD41] to-[#27AC45] m-auto my-2"></div>
          <p className="text-center px-5 text-[#383838] ">{item.summary}</p>
        </div>
      </div>
    ));
  };

  return <Slider {...settings}>{renderSlides()}</Slider>;
}

export default Sliderhome;
