import React from "react";
import Videos from "../flacks/Videos";

const CareerWithHomeVideo = () => {
  return (
    <>
      <div className="bg-white">
        <h1 className="text-center text-4xl  text-gray-800 font-bold">
          Careers at Petrochem
        </h1>
        <hr className="m-5" />
        <div className="bg-white p-10 flex flex-row lg:flex-row sm:flex-col md:flex-row xs:flex-col justify-around ">
          <div className=" w-[50%] p-5">
            <h1 className="text-3xl font-bold py-5 text-gray-800">
              Build Your Career With us
            </h1>
            <p className="text-gray-500">
              We are constantly looking for professionals who befit the culture
              of our organization and have an undying spirit to reach new
              heights in their professional careers. We stand by the values that
              made us who we are today. They’ve shaped our culture, our work
              ethics, and our decisions; helping us push the envelope and be
              more than what we were yesterday.
            </p>
            <div className="bg-gradient-to-r from-[#6CBD41] to-[#27AC45] my-10  p-5 justify-center rounded-md">
              <h1 className="text-white text-2xl font-semibold">
                SEND YOUR CV TODAY{" "}
              </h1>
              <div className="h-[3px] bg-white w-full my-3"></div>
              <h3 className=" font-bold sm:text-lg lg:text-xl md:text-lg text-white">
                e-mail us at : monia@petrochembd.com{" "}
              </h3>
            </div>
          </div>
          <div className=" w-[50%] p-5">
            <Videos />
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerWithHomeVideo;
