import React, { useState } from "react";

const VerticalTabs = ({ tabs }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="flex px-20">
      <div className="lg:w-[20%] md:w-[30%] sm:w-[40%] xs:w-[50%] h-auto overflow-y-auto border-r border-gray-300">
        {tabs.map((tab, index) => (
          <button
            key={tab.label}
            onClick={() => setActiveIndex(index)}
            className={`my-3 block py-2 px-6 lg:text-2xl md:text-2xl sm:text-xl xs:text-xl text-left text-gray-700 font-medium  ${
              activeIndex === index
                ? "bg-green-500 text-white"
                : "hover:bg-green-400 hover:text-white"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="lg:w-[80%] md:w-[70%] sm:w-[60%] xs:w-[50%] p-5">
        {tabs[activeIndex].content}
      </div>
    </div>
  );
};

export default VerticalTabs;
