import React from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { homemissionvision } from "../../data/contentdata";
const HomemissionVision = () => {
  return (
    <div className=" bg-white max-w-[100%] font-[Poppins] lg:flex md:flex sm:block xs:block w-[100%] justify-center lg:p-10 md:p-10 sm:p-5 xs:p-3">
      <div className=" p-10">
        <h1 className="text-4xl font-bold text-[#383838] py-5">
          {homemissionvision.title}
        </h1>
        <p className="py-5 text-xl font-semibold text-[#383838]">
          {homemissionvision.subtitle}
        </p>
        <div className="flex py-10 gap-5 flex-wrap justify-center">
          <div className="p-5">
            <div className="flex items-center mx-2 ">
              <FaAngleDoubleRight />
              <h3 className="text-2xl font-bold text-[#383838] mx-2 py-3">
                Vision
              </h3>
            </div>
            <p className="w-[250px] text-gray-500">
              {homemissionvision.vision}
            </p>
          </div>
          <div className="p-5">
            <div className="flex items-center mx-2">
              <FaAngleDoubleRight />
              <h3 className="text-2xl font-bold text-[#383838] mx-2 py-3">
                Mission
              </h3>
            </div>

            <p className="w-[250px] text-gray-500">
              {homemissionvision.mission}
            </p>
          </div>
          <div className="p-5">
            <div className="flex items-center mx-2">
              <FaAngleDoubleRight />
              <h3 className="text-2xl font-bold text-[#383838] mx-2 py-3">
                Key Support
              </h3>
            </div>

            <p className="w-[250px] text-gray-500">
              {homemissionvision.support}
            </p>
          </div>
          <div className="p-5">
            <div className="flex items-center mx-2">
              <FaAngleDoubleRight />
              <h3 className="text-2xl font-bold text-[#383838] mx-2 py-3">
                Regulations
              </h3>
            </div>
            <p className="w-[250px] text-gray-500">
              {homemissionvision.regulation}
            </p>
          </div>
        </div>
      </div>

      <img
        className="h-[100%] lg:p-24 md:p-20 sm:p-10 xs:p-4 inline-block text-center"
        src="https://ik.imagekit.io/txh5evivcha5/pclstatic/yeppi__1__g4Tgo9hncj.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676779329073"
        alt=""
      />
    </div>
  );
};

export default HomemissionVision;
