import React from "react";

import { FaCaretRight } from "react-icons/fa";
const SectorFlaks = () => {
  return (
    <div className="">
      <div className="py-5 flex">
        <div className="w-96 bg-gradient-to-r from-[#6CBD41] to-[#27AC45] ml-20 relative">
          <h1 className="text-2xl font-semibold text-white px-10 py-5">
            Sector
          </h1>
          <p className="text-white px-10 pb-5">Petrochem taking cares</p>

          <FaCaretRight className="absolute right-[-20px] top-[45px] text-3xl text-[#27AC45]" />
        </div>

        <div className="bg-cover bg-center ml-10 w-[720px]" style={{backgroundImage: `url('https://ik.imagekit.io/txh5evivcha5/pclstatic/online-farmers-markets-2-889x592-1_-ieGcK6Hd.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675855122293')`}} >
           
        </div>
      </div>
    </div>
  );
};

export default SectorFlaks;
