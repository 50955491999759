import React from 'react';
import SisterConcernHeader from '../components/article/SisterConcernHeader';
import ConcernCard from '../components/article/ConcernCard';
const SisterConcerns = () => {
  return (
    <div className='bg-white'>
      <SisterConcernHeader/>
      <ConcernCard/>
    </div>
  )
}

export default SisterConcerns