import React from "react";
import NewsLink from "./NewsLink";
import NewsVideo from "./NewsVideo";
import GalleryNews from "./GalleryNews";
function NewsCsr() {
  return (
    <div>
      <div className="w-[full] flex justify-center my-10 mx-20">
        <h1 className=" text-4xl font-bold text-gray-600"> Videos on CSR</h1>
      </div>
      <div>
        <NewsVideo />
      </div>
      <div className="w-[full] flex justify-center my-20 mx-20">
        <h1 className=" text-4xl font-bold text-gray-600">Latest News and Activity (CSR)</h1>
      </div>
      <div>
        <NewsLink />
      </div>
      <div>
        <GalleryNews />
      </div>
    </div>
  );
}

export default NewsCsr;
