import React from "react";

const SisterConcernHeader = () => {
  return (
    <div>
      <div className=" lg:mx-40 md:mx-32 sm:mx-20 p-5 flex  gap-10 justify-between items-center">
        <div className=" w-[96] h-[96] bg-[url('https://ik.imagekit.io/txh5evivcha5/pclstatic/dots_3cgcsfT3y.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675855121084')]">
          <img
            className="border-white border-8 rounded-full"
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/rice_agro-306x306_SGe-nNQmq.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676353286575"
            alt="farmers-smile"
          />
        </div>
        <div className="bg-[#1D1B4C] w-[1000px] lg:py-32 md:py-24 sm:py-20 xs:py-10">
          <h1 className="lg:text-3xl md:text-2xl sm:text-lg text-white font-bold font-[Poppins] text-center">
            Our Sister Concerns
          </h1>
        </div>
      </div>
    </div>
  );
};

export default SisterConcernHeader;
