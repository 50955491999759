import React from "react";

const LargeImagebox = () => {
  return (
    <div className="m-20">
      <div className='flex justify-end relative before:content-[url("https://ik.imagekit.io/txh5evivcha5/pclstatic/dots_3cgcsfT3y.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675855121084")] before:absolute before:top-5 before:left-0 before:z-[-1] after:content[""] after:w-12 after:h-24 after:bg-gradient-to-r from-[#6CBD41] to-[#27AC45] after:absolute after:left-40 after:bottom-0 after:z-[-2]'>
        <img
          className="lg:h-[700px] md:h-[600px]"
          src="https://ik.imagekit.io/txh5evivcha5/pclstatic/leaders-meet_9-5gCW2tqp.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675855121242"
          alt=""
        />
      </div>
    </div>
  );
};

export default LargeImagebox;
