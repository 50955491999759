import React from "react";

const Susright = ({ title, message, image, icon }) => {
  return (
    <div className="p-20">
      <div className="flex mx-10 justify-center">
        <div className=" flex bg-green-600 text-white text-xl rounded-full p-2 text-center h-10 w-10">
          {icon}
        </div>

        <h1 className="text-3xl font-semibold mx-2 text-gray-500">{title}</h1>
      </div>

      <div className="lg:flex md:flex sm:block xs:block gap-10">
        <div className="p-10">
          <img className="w-96" src={image} alt="" />
        </div>
        <p className="lg:w-[60%] md:w-[60%] sm:w-[100%] xs:w-[100%] p-10 text-gray-500">{message}</p>
      </div>
    </div>
  );
};

export default Susright;
