import React, { useState } from "react";
import { FaCreativeCommonsBy } from "react-icons/fa";
const Accordion = (props) => {
  const [panels, setPanels] = useState(props.panels);
  const togglePanel = (index) => {
    setPanels((prevState) =>
      prevState.map((panel, i) => {
        if (i === index) {
          return { ...panel, isOpen: !panel.isOpen };
        } else {
          return { ...panel, isOpen: false };
        }
      })
    );
  };
  return (
    <div className="px-20 my-10 ">
      {panels.map((panel, index) => (
        <div key={index} className="border rounded-lg shadow-md active:shadow-lg ">
          <div
            className="bg-[#dddddd90] py-5 "
            onClick={() => togglePanel(index)}
          >
            <div className="flex justify-between px-12 ">
              <h2 className="text-xl font-bold text-[#383838] flex items-center "><FaCreativeCommonsBy className="mr-2 text-green-700"/>  {panel.title} </h2>
              <span className="text-2xl font-semibold">{panel.isOpen ? "-" : "+"}</span>
            </div>
          </div>
          {panel.isOpen && <div className="lg:px-20 lg:py-10 md:px-20 md:py-8 sm:px-8 sm:py-6 xs:px-6 xs:py-4 bg-[#dddddd90] font-[Poppins] text-[#383838]">{panel.content}</div>}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
