import React from "react";
import { newsvideos } from "../../data/csrnewsdata";
const NewsVideo = () => {
  return (
    <div className="mx-24 bg-gray-50 flex shadow-sm flex-wrap justify-around">
      <div className="my-6">
        <video
          src={newsvideos.vid1.videolink}
          height={320}
          width={460}
          controls={true}
        />
        <h1 className="text-xl font-bold py-2 w-auto text-gray-500">
          {newsvideos.vid1.title}
        </h1>
      </div>

      <div className="my-6">
        <iframe
          width="460"
          height="260"
          src="https://www.youtube.com/embed/usPRnUGp-r0?si=yMfXPW65B8B0gW_3"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
          allowfullscreen></iframe>
        <h1 className="text-xl font-bold py-2 w-[400px] text-gray-500">
          {newsvideos.vid2.title}
        </h1>
      </div>
      <div className="my-6">
        <iframe
          title="YouTube video player"
          width="460"
          height="260"
          frameborder="0"
          src="https://mega.nz/embed/4ggAzBwL#fwgpgLAAhIy68bDSTCRhT4_UKUvqgigPenSruCUROX0"
          allowfullscreen></iframe>
        <h1 className="text-xl font-bold py-2 w-[400px] text-gray-500">
          {newsvideos.vid3.title}
        </h1>
      </div>
      <div className="my-6">
        <iframe
          title="YouTube video player"
          width="460"
          height="260"
          frameborder="0"
          src="https://mega.nz/embed/ItRDQBoS#uGrHb_mw0io8ehCbvOYUXtlw3cnn6E-9-xnS8Hx-bg8"
          allowfullscreen></iframe>

        <h1 className="text-xl font-bold py-2 w-[400px] text-gray-500">
          {newsvideos.vid4.title}
        </h1>
      </div>
    </div>
  );
};

export default NewsVideo;
