import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { testislider } from "../../data/contentdata";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="h-10 w-10  bg-gray-400 absolute right-5 top-52 flex justify-center items-center rounded-full text-white opacity-50 hover:opacity-100"
      onClick={onClick}
    >
      <FaAngleRight />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="h-10 w-10  bg-gray-400 absolute left-5 top-52 flex justify-center items-center rounded-full text-white opacity-50 hover:opacity-100 z-10"
      onClick={onClick}
    >
      <FaAngleLeft />
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

function Dslider() {
  const renderSlides = () => {
    return testislider.map((item) => (
      <div className="py-24 ">
        <figure class="max-w-screen-md mx-auto text-center">
          <svg
            aria-hidden="true"
            class="w-12 h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
            viewBox="0 0 24 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
              fill="currentColor"
            />
          </svg>

          <p class="text-lg italic font-light text-white">{item.comments}</p>

          <figcaption class="flex items-center justify-center mt-6 space-x-3">
            <img
              class="w-12 h-12 rounded-full"
              src={item.photo}
              alt="profile"
            />
            <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
              <cite class="pr-3 font-medium text-green-500">{item.name}</cite>
              <cite class="pl-3 text-sm font-light text-gray-100">
                {item.title}
              </cite>
            </div>
          </figcaption>
        </figure>
      </div>
    ));
  };

  return <Slider {...settings}>{renderSlides()}</Slider>;
}

export default Dslider;
