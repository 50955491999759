import React from "react";
import HeaderBreadCrumb from "../components/article/HeaderBreadCrumb";
import PartnerTitle from "../components/flacks/PartnerTitle";

import { partners } from "../data/contentdata";

const OurPartners = () => {
  return (
    <div className="bg-white">
      <HeaderBreadCrumb
        url="https://ik.imagekit.io/txh5evivcha5/pclstatic/floriane-vita-FyD3OWBuXnY-unsplash_liUXBF2ct.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675245698122"
        title="Our partners"
        link="/whoweare"
        breadCrubName="Who we are"
      />
      <div className="py-20 lg:py-10 md:py-6 sm:py-4">
        <PartnerTitle partnerType="Business Partner" />
        <div className="flex ml-12 flex-wrap">
          {partners.businessPartner.map((imglink) => (
            <div className="border-gray-500-400 border p-8 m-2">
              <img
                className="w-100% hover:scale-[1.2] transition duration-100 ease-in-out"
                src={imglink.imagesrc}
                alt="logo"
              />
            </div>
          ))}
        </div>

        <PartnerTitle partnerType="Financial Partners" />
        <div className="flex ml-12 flex-wrap">
          {partners.financialPartners.map((imglink) => (
            <div className="border-gray-500-400 border p-8 m-2">
              <img
                className="w-100% hover:scale-[1.2] transition duration-100 ease-in-out"
                src={imglink.imagesrc}
                alt="logo"
              />
            </div>
          ))}
        </div>
        <PartnerTitle partnerType="Development Partners" />
        <div className="flex ml-12 flex-wrap">
          {partners.developmentPartners.map((imglink) => (
            <div className="border-gray-500-400 border p-8 m-2">
              <img
                className="w-100% hover:scale-[1.2] transition duration-100 ease-in-out"
                src={imglink.imagesrc}
                alt="logo"
              />
            </div>
          ))}
        </div>
        <PartnerTitle partnerType="Communication Partners" />
        <div className="flex ml-12 flex-wrap">
          {partners.communicationPartners.map((imglink) => (
            <div className="border-gray-500-400 border p-8 m-2">
              <img
                className="w-100% hover:scale-[1.2] transition duration-100 ease-in-out"
                src={imglink.imagesrc}
                alt="logo"
              />
            </div>
          ))}
        </div>
        <PartnerTitle partnerType="Media Partners" />
        <div className="flex ml-12 flex-wrap">
          {partners.mediaPartners.map((imglink) => (
            <div className="border-gray-500-400 border p-8 m-2">
              <img
                className="w-100% hover:scale-[1.2] transition duration-100 ease-in-out"
                src={imglink.imagesrc}
                alt="logo"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurPartners;
