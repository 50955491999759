import React from "react";

const AboutCompany = () => {
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mt-6 lg:mt-14 ">
            <img
              src="https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/crystal-kwok-XUEdfpPIhXg-unsplash_aep-qm_aU.jpg?updatedAt=1716045656021"
              alt="Company"
              className="w-full rounded-lg shadow-lg"
            />
            <div className="mt-6">
              <div className="inline-flex items-center px-3 py-0.5 rounded-full text-lg font-medium bg-red-100 text-red-800">
                30 years of experience
              </div>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              WELCOME TO{" "}
              <span className="text-cyan-700">
                International Services Limited !
              </span>
            </h2>
            <h3 className="mt-4 text-xl font-bold text-gray-900">
              We execute our ideas from start to finish by supplying the best
              industry machinery and offering comprehensive product support
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              With more than 15 years in the business and as a pioneer exporter
              in the Global market, ISL (International Services Limited) feels
              proud to be the country’s Best manufacturer machineries and
              service provider.
            </p>
            <p className="mt-3 text-lg text-gray-500">
              At ISL, we specialize in providing innovative solutions and
              high-quality products to meet the unique needs of our clients.
              Within our primary range of products, we feature Sullair Screw Air
              Compressors hailing from both the USA and China, SKF Industrial
              Bearings originating from Sweden, Nederman Industrial Filtration
              Systems also sourced from the same region in Sweden, Kirloskar
              Electric Chillers made in India.
            </p>
            <ul className="mt-8 space-y-5">
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="ml-3 text-base text-gray-500">
                  Perform Market Research
                </p>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="ml-3 text-base text-gray-500">
                  Provide information to the clients
                </p>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="ml-3 text-base text-gray-500">
                  Strategic Planning
                </p>
              </li>
            </ul>
            <div className="mt-8 sm:flex">
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <div className="rounded-md shadow">
                  <a
                    href="/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-cyan-700 hover:bg-white hover:text-cyan-700 md:py-4 md:text-lg md:px-10"
                  >
                    Discover More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
