import React from "react";
import { AiOutlineTrophy } from "react-icons/ai";
import { chairmanMessage } from "../../data/contentdata";
const Cmessage = () => {
  return (
    <div className="p-10 flex gap-5 md:flex-row lg:flex-row sm:flex-col xs:flex-col justify-center">
      <div className="lg:w-[50%] md:w-[50%] xs:block sm:block p-3">
        <div className="w-20 h-2 bg-green-600 lg:inline-block md:inline-block sm:inline-block xs:hidden"></div>
        <h1 className="lg:text-4xl md:text-4xl sm:text-3xl xs:text-2xl py-5 font-bold text-gray-800">
          {chairmanMessage.title}
        </h1>
        <p className="py-5 lg:leading-10 md:leading-10 sm:leading-8 xs:leading-5 text-lg text-gray-500">
          {chairmanMessage.message}
        </p>
        <div className="w-96 h-[1px] bg-slate-300 mt-2"></div>
        <p className="pt-10 text-2xl font-semibold text-gray-500">
          {chairmanMessage.signame}
        </p>
        <p className="py-2 text-gray-500">({chairmanMessage.designation})</p>
      </div>

      <div className=" lg:w-[50%] p-3 m-auto relative sm:w-[100%] md:w-[50%] xs:w-[100%]">
        <img
          className="  sm:w-[800px] "
          src="https://ik.imagekit.io/txh5evivcha5/pclstatic/MD_Sir_Pic_76kaJCPNb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675581434926"
          alt="ex-chairman"
        />

        <div className=" border-[5px] border-white shadow-2xl m-5 rounded-full bg-green-600 absolute right-[-2%] bottom-[-5%] p-16 w-16 h-10 flex justify-center items-center">
          <div>
            <AiOutlineTrophy className="text-white text-5xl" />
            <p className="text-[10px] text-center font-semibold text-white">
              34 years of success
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cmessage;
