import React from "react";
import Teamdetails from "../components/article/Teamdetails";
import { ourteam } from "../data/contentdata";
const Md = () => {
  return <div className="py-24 bg-white">
    <Teamdetails name={ourteam.managingdirector.name} designation={ourteam.managingdirector.designation} details={ourteam.managingdirector.shortsummary} photo={ourteam.managingdirector.photo}/>
  </div>;
};

export default Md;
