import React from "react";
import Susright from "../components/article/Susright";
import Susleft from "../components/article/Susleft";
import { sustainability } from "../data/contentdata";
import HeaderBreadCrumb from "../components/article/HeaderBreadCrumb";
import { FaLeaf, FaWater, FaShieldVirus } from "react-icons/fa";
const Sustainability = () => {
  return (
    <div className="bg-white">
      <HeaderBreadCrumb
        url="https://ik.imagekit.io/txh5evivcha5/pclstatic/SustainBanner1_f6dZcrxleq.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675846201101"
        title="Sustainability"
        link="/"
        breadCrubName="Home"
      />
      <h1 className="text-4xl px-20 text-center py-10 font-bold text-gray-700">
        <span className="text-[#01D941]">DUAL HEADING</span> EXAMPLE
      </h1>
      <Susleft
        title={sustainability.data1.title}
        message={sustainability.data1.message}
        image={sustainability.data1.image}
        icon=<FaLeaf />
      />

      <hr className="mx-20" />
      <Susright
        title={sustainability.data2.title}
        message={sustainability.data2.message}
        image={sustainability.data2.image}
        icon=<FaShieldVirus />
      />
      <hr className="mx-20" />
      <Susleft
        title={sustainability.data3.title}
        message={sustainability.data3.message}
        image={sustainability.data3.image}
        icon=<FaWater />
      />
    </div>
  );
};

export default Sustainability;
