import React from "react";
import {
  FaFacebook,
  FaGooglePlus,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
const SocialLinks = () => {
  return (
    <div className="flex">
      <div className=" hover:translate-y-[-2px] transition ease-in-out duration-150 rounded-full h-6 w-6 bg-gradient-to-r from-[#0b9965] to-[#00d084] shadow-sm flex justify-center items-center text-white ml-2">
        <FaFacebook />
      </div>
      <div className="hover:translate-y-[-2px] transition ease-in-out duration-150 rounded-full h-6 w-6 bg-gradient-to-r from-[#0b9965] to-[#00d084] shadow-sm flex justify-center items-center text-white ml-2">
        <FaGooglePlus />
      </div>
      <div className="hover:translate-y-[-2px] transition ease-in-out duration-150 rounded-full h-6 w-6 bg-gradient-to-r from-[#0b9965] to-[#00d084] shadow-sm flex justify-center items-center text-white ml-2">
        <FaYoutube />
      </div>
      <div className="hover:translate-y-[-2px] transition ease-in-out duration-150 rounded-full h-6 w-6 bg-gradient-to-r from-[#0b9965] to-[#00d084] shadow-sm flex justify-center items-center text-white ml-2">
        <FaLinkedin />
      </div>
    </div>
  );
};

export default SocialLinks;
