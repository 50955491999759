import React from "react";
import { Link } from "react-router-dom";
const Backgroundphotos = () => {
  return (
    <div
      className=" bg-cover bg-center bg-fixed my-10"
      style={{
        backgroundImage: `url('https://ik.imagekit.io/txh5evivcha5/pclstatic/chemical-factory_FmXr7OS9e.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675927596654')`,
      }}
    >
      <div className="flex my-10">
        <div className="w-[50%]"></div>
        <div className="w-[50%] h-[100%] bg-cover bg-center bg-black   py-[300px] pl-10 opacity-95">
          <h1 className="text-4xl font-bold z-10 text-white pr-32 py-8 leading-[48px]">
            এখন আরো উন্নত আরও আধুনিক
          </h1>
          <p className="text-white py-5 pr-44">
            কৃষকের যেকোনো প্রয়োজনে পেট্রোকেম ছিল, আছে এবং আগামীতেও থাকবে
          </p>

          <Link
            to="/contact-us"
            className="bg-gradient-to-r from-[#6CBD41] to-[#27AC45] px-10 py-5 my-5 text-white font-semibold text-xl rounded-md shadow-md inline-block"
          >
            Getting started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Backgroundphotos;
