import React from 'react'

const Copyright = () => {
  return (
    <div className='bg-gray-800 text-center py-5'>
        <p className='text-white'>2023 © All rights reserved by <span className='text-green-600'>Petrochem IT </span></p>
    </div>
  )
}

export default Copyright