import React from "react";
import Leftwho from "../components/article/Leftwho";
import Rightwho from "../components/article/Rightwho";
import Backgroundphotos from "../components/flacks/Backgroundphotos";
import { whoweare } from "../data/contentdata";
const WhoweAre = () => {
  return (
    <div className="py-10 bg-white">
      <div className="lg:flex md:block">
        <div className="lg:w-[50%] md:w-[100%] sm:w-[100%] xs:w-[100%] md:mx-auto">
          <Leftwho />
        </div>
        <div className="lg:w-[50%] md:w-[100%] sm:w-[100%] xs:w-[100%] md:mx-auto">
          <Rightwho />
        </div>
      </div>

      <hr className="mx-20" />

      <div className="lg:flex md:flex sm:block xs:block p-10 py-20">
        <div className="lg:w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%]">
          <img
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/MD_Sir_Pic_76kaJCPNb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675581434926"
            alt="Chairman Sir"
          />
        </div>
        <div className="lg:w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%] p-10">
          <div className="flex items-center">
            <div className="h-1 w-20 bg-green-600 px-2"></div>
            <h1 className="text-xl font-bold pl-4 text-gray-500">{whoweare.cmtitle}</h1>

          </div>
            <p className="leading-[30px] font-[Poppins] text-[#383838] py-5">{whoweare.cmmessage}</p>
        </div>
      </div>

      <Backgroundphotos />
    </div>
  );
};

export default WhoweAre;
