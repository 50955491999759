import React from "react";

const HistoryTab = () => {
  return (
    <div
      className=" py-10 w-full bg-fixed bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('https://ik.imagekit.io/txh5evivcha5/pclstatic/1556036536_KiV7I84Qi.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675666341054')`,
      }}
    >
      <div className="flex justify-center py-20 items-center md:flex-row lg:flex-row sm:flex-col xs:flex-col ">
        <div className="px-20">
          <div className=" ml-5 w-20 h-1 bg-green-600"></div>
          <h1 className="pl-5 text-3xl font-bold text-white py-3">
            Crucial Steps by Petrochem (Bangladesh) Limited.
          </h1>
        </div>
        <p className="px-10 text-yellow-100">
          Establishment of an Unicorn in Agro-Chemical industry of Bangladesh
        </p>
      </div>

      <div className="max-w-[970px] mx-auto pt-[34px] pb-[140px] relative before:content-[''] before:h-[100%] before:border-l-2 before:border-white-300 before:border-dotted before:top-0 before:left-0 before:right-0 before:absolute before:m-auto before:w-0 ">
        <div className="text-center rounded-full text-[#f6f6f6] w-24 h-24 text-2xl font-bold left-0 top-0 right-0 mx-auto z-50 absolute bg-gradient-to-r from-[#25ac45] to-[#6fbd40] flex justify-center items-center">
          Start
        </div>

        <div className="flex flex-wrap">
          <div className="w-[50%]">
            <div className="relative mt-[100px]  text-right pr-[124px] before:content-[''] before:h-0 before:w-[114px] before:border-dashed before:border-b-2 before:border-white before:absolute before:top-[15px] before:right-0 before:z-1 before:opacity-75 after:content-[''] after:h-5 after:w-5 after:rounded-full after:absolute after:top-1 after:right-[-11px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">2019</h3>
                <p className="text-white font-medium">
                  Connected 15K + Farmers
                </p>
              </div>
            </div>

            <div className="relative mt-[80px] text-right pr-[124px] before:content-[''] before:h-0 before:w-[114px] before:border-dashed before:border-b-2 before:border-white before:absolute before:top-[15px] before:right-0 before:z-1 before:opacity-75 after:content-[''] after:h-5 after:w-5 after:rounded-full after:absolute after:top-1 after:right-[-11px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">2014</h3>
                <p className="text-white font-medium">Crossed Growth to IPO</p>
              </div>
            </div>
            <div className="relative mt-[100px]  text-right pr-[124px] before:content-[''] before:h-0 before:w-[114px] before:border-dashed before:border-b-2 before:border-white before:absolute before:top-[15px] before:right-0 before:z-1 before:opacity-75 after:content-[''] after:h-5 after:w-5 after:rounded-full after:absolute after:top-1 after:right-[-11px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">2006</h3>
                <p className="text-white font-medium">
                  The purpose of the business plan
                </p>
              </div>
            </div>

            <div className="relative mt-[80px] text-right pr-[124px] before:content-[''] before:h-0 before:w-[114px] before:border-dashed before:border-b-2 before:border-white before:absolute before:top-[15px] before:right-0 before:z-1 before:opacity-75 after:content-[''] after:h-5 after:w-5 after:rounded-full after:absolute after:top-1 after:right-[-11px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">1997</h3>
                <p className="text-white font-medium">
                  Focus business history on what matters to planning
                </p>
              </div>
            </div>
            <div className="relative mt-[80px] text-right pr-[124px] before:content-[''] before:h-0 before:w-[114px] before:border-dashed before:border-b-2 before:border-white before:absolute before:top-[15px] before:right-0 before:z-1 before:opacity-75 after:content-[''] after:h-5 after:w-5 after:rounded-full after:absolute after:top-1 after:right-[-11px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">1990</h3>
                <p className="text-white font-medium">Beginning of petrochem</p>
              </div>
            </div>
          </div>
          <div className="w-[50%]">
            <div className="mt-0 pr-0 pl-[124px] relative z-1 before:left-0 before:right-auto before:content-[''] before:h-0 before:w-[114px] before:border-b before:border-dashed before:border-white before:border-[1px] before:absolute before:top-[15px]  before:z-1 after:right-auto after:left-[-11px] after:content-[''] after:w-5 after:h-5 after:rounded-full after:absolute after:top-[4px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">2024</h3>
                <p className="text-white font-medium">
                  Hyper Farming & AI Service
                </p>
              </div>
            </div>
            <div className="mt-[120px] pr-0 pl-[124px] relative z-1 before:left-0 before:right-auto before:content-[''] before:h-0 before:w-[114px] before:border-b before:border-dashed before:border-white before:border-[1px] before:absolute before:top-[15px]  before:z-1 after:right-auto after:left-[-11px] after:content-[''] after:w-5 after:h-5 after:rounded-full after:absolute after:top-[4px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">2015</h3>
                <p className="text-white font-medium">
                  Established Petrochem Group
                </p>
              </div>
            </div>
            <div className="mt-[80px] pr-0 pl-[124px] relative z-1 before:left-0 before:right-auto before:content-[''] before:h-0 before:w-[114px] before:border-b before:border-dashed before:border-white before:border-[1px] before:absolute before:top-[15px]  before:z-1 after:right-auto after:left-[-11px] after:content-[''] after:w-5 after:h-5 after:rounded-full after:absolute after:top-[4px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">2012</h3>
                <p className="text-white font-medium">
                  Best Award Winning Company
                </p>
              </div>
            </div>
            <div className="mt-[90px] pr-0 pl-[124px] relative z-1 before:left-0 before:right-auto before:content-[''] before:h-0 before:w-[114px] before:border-b before:border-dashed before:border-white before:border-[1px] before:absolute before:top-[15px]  before:z-1 after:right-auto after:left-[-11px] after:content-[''] after:w-5 after:h-5 after:rounded-full after:absolute after:top-[4px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">2002</h3>
                <p className="text-white font-medium">
                  For lean business plans, operational plans, and strategic
                  plans
                </p>
              </div>
            </div>
            <div className="mt-[70px] pr-0 pl-[124px] relative z-1 before:left-0 before:right-auto before:content-[''] before:h-0 before:w-[114px] before:border-b before:border-dashed before:border-white before:border-[1px] before:absolute before:top-[15px]  before:z-1 after:right-auto after:left-[-11px] after:content-[''] after:w-5 after:h-5 after:rounded-full after:absolute after:top-[4px] after:bg-gradient-to-r from-[#25ac45] to-[#6fbd40]">
              <div>
                <h3 className="text-2xl font-bold text-white">1994</h3>
                <p className="text-white font-medium">
                  Established Agro Chemical Factory
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className=" rounded-full border-2-[#474747] absolute bottom-[-20px] left-0 right-0 m-auto text-center">
          <img
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/fartilizer-150x150_3HcwKNZGz.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675682525364"
            alt=""
            className="rounded-full inline-block "
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryTab;
