import React from "react";
import Corpcard from "../cards/Corpcard";
const Oursolutions = () => {
  return (
    <div
      className="bg-no-repeat bg-cover bg-fixed bg-center max-w-[100%] z-[-10] font-[Poppins] bg-white"
      style={{
        backgroundImage:
          "url('https://ik.imagekit.io/txh5evivcha5/pclstatic/markus-spiske-0LjNy5lyYvk-unsplash_UnsuD92Yl.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676779337839')",
      }}
    >
      <div className="w-[100%] h-[100%] bg-[#00000095] z-10 p-y-20">
        <h1 className="px-20 lg:w-[60%] md:w-[80%] sm:w-[100%] xs:w-[100%] leading-[46px] text-white py-24 lg:text-4xl md:text-4xl sm:text-3xl xs:text-3xl font-[Poppins] font-bold">
          We position our farmers at the forefront of their business by
          providing 365 solutions.
        </h1>
        <div className="p-10 flex items-center justify-start">
          <Corpcard />
        </div>
      </div>
    </div>
  );
};

export default Oursolutions;
