import React from "react";

const Services = () => {
  const services = [
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/research_otkI_BjrT.png?updatedAt=1715071948947",
      title: "Research & Development",
      description:
        "As much as we believe in innovation as we believe in quality. To achieve excellence, we continually make investments in research and development (R&D).",
    },
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/secret-file_J1SHaeXgI.png?updatedAt=1715071948682",
      title: "Experience you can trust",
      description:
        "As much as we believe in innovation as we believe in quality. To achieve excellence, we continually make investments in research and development (R&D).",
    },
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/handshake_qBmQ3eWB48.png?updatedAt=1715071934660",
      title: "Business Support",
      description:
        "Our dedicated team provides tailored solutions to streamline operations and drive growth in the industrial machinery sector. From strategic planning to supply chain management, we're committed to your success. Let's elevate your business together.",
    },
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/certificate_pUHUZR8Oh.png?updatedAt=1715071949045",
      title: "Certified Products",
      description:
        "The Bangladesh Government’s Boiler Inspection Authority has certified ISL as the Pioneer Boiler Manufacturer and Supplier. All of Our products are ISO certified and evaluated in highly regulated industries of Bangladesh.",
    },
    {
      icon: "https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/business-analysis_HQfyAWYnZM.png?updatedAt=1715071949031",
      title: "Economic & Efficient",
      description:
        "Our innovative products are more efficient. Its High Efficiency and Less Fuel Consumption feature will help you save Gas/Oil costs and Natural Resources. As a result, you will save money on your energy expenses!",
    },
  ];

  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-cyan-700 sm:text-4xl">
            Machinery Solutions for Tomorrow's Challenges
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden transition duration-300 transform hover:scale-105"
            >
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-center mb-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10"
                      src={service.icon}
                      alt={service.title}
                    />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      {service.title}
                    </h3>
                  </div>
                </div>
                <div className="mt-2 text-base text-gray-500">
                  {service.description}
                </div>
                <div className="mt-4">
                  <a
                    href="/"
                    className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
