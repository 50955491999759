import React from "react";
import { message } from "../../data/contentdata";
import SocialLinks from "../flacks/SocialLinks";
const Navbartop = () => {
  return (
    <div className="bg-[#383838] sm:block hidden">
      <div className=" flex justify-between py-2 lg:px-16 md:px-8 sm:px-4 xs:px-2">
        <p className="text-white">
          <span className="text-emerald-500 font-semibold">{message.welcomeText}</span> <span>{message.petrochem}</span>
        </p>
        <div className="sm:block hidden">
          <SocialLinks />
        </div>
      </div>
    </div>
  );
};

export default Navbartop;
