import React from 'react'

const SmallUpperhead = () => {
  return (
    <div className='my-2 flex items-center gap-5'>
        <div className='h-1 bg-green-600 w-10'></div>
        <p className=' text-gray-600 py-3 font-Poppins'>Who we are</p>
    </div>
  )
}

export default SmallUpperhead