import React from 'react'

const Vision = () => {
  return (
    <div className="py-10 bg-white">
    <div className="flex ml-52 lg:ml-52 md:ml-28 sm:ml-0  lg:flex md:flex sm:block xs:block xs:ml-0">
      <img
        className="xs:mx-auto rounded left-40 top-20"
        src="https://ik.imagekit.io/txh5evivcha5/pclstatic/benjamin-davies-JrZ1yE1PjQ0-unsplash-306x306_sjvUNJmXA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675317425932"
        alt=""
      />
      <div className="bg-green-300 px-10 text-gray-800 sm:my-5 xs:my-5 py-10">
        <p className="text-xl font-semibold py-2">Petrochem group</p>
        <h1 className="text-4xl font-bold">Our Vision in Upcoming Years</h1>
        <p className="text-gray-900 py-3">
          Petrochem’s vision is to play a leading role in improving the
          quality of life and well being of the people of Bangladesh through
          responsible application of scientific knowledge and modern skills.
        </p>
      </div>
    </div>
  </div>
  )
}

export default Vision