import React from "react";
import GalleryHome from "../components/slider/GalleryHome";
const GallerySectionHome = () => {
  return (
    <div className="p-20 font-[Poppins] bg-white">
      <div className="flex justify-between py-20">
        <h1 className="text-bold text-4xl w-[550px] pr-10 leading-[56px] font-bold  text-black">
          পেট্রোকেম সমাজের অর্থনৈতিক উন্নয়নের জন্য চেষ্টা করে।
        </h1>
        <p className="text-lg leading-[30px] w-[650px] pl-10 text-gray-500">
          Evaluation of new and current formulations in different agro-eco
          regions of the country. Promotion and creating awareness about the new
          products
        </p>
      </div>
      <div>
        <GalleryHome />
      </div>
    </div>
  );
};

export default GallerySectionHome;
