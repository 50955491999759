import React from "react";
import Lightbox from "yet-another-react-lightbox";
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import { imagesdata } from "../../data/csrnewsdata";

const slides = imagesdata.map(({ original, width, height }) => ({
  src: original,
  width:700,
  height:700,
}));

const GalleryNews = () => {
  const [index, setIndex] = useState(-1);

  const handleClick = (index, item) => setIndex(index);

  return (
    <div className="mx-14 mt-24">
      <h1 className="text-center text-2xl font-bold py-6 my-6 text-gray-500">
        খাদ্য নিরাপত্তা নিশ্চিত করার লক্ষ্যে পেট্রোকেম বাংলাদেশ লিমিটেডের সিএসআর
        প্রকল্পের আওতায় আসন্ন বোরো মৌসমের চাষিদের মাঝে বিনামূল্যে কৃষি উপকরণ
        বিতরণ করছে ।{" "}
      </h1>
      <Gallery
       
        images={imagesdata}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
};

export default GalleryNews;
