import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { gallaryalbum } from "../../data/contentdata";
const GalleryHome = () => {
  const [teamalbum, setTeamalbum] = React.useState(false);
  const [farmer, farmerOpen] = React.useState(false);
  const [meeting, meetingOpen] = React.useState(false);
  const [csr, csrOpen] = React.useState(false);
  const [culture, cultureOpen] = React.useState(false);
  const [togather, togatherOpen] = React.useState(false);
  

  return (
    <>
      <div className="p-3 bg-black">
      <div className="flex justify-between">
        <div>
          <img
            onClick={() => setTeamalbum(true)}
            className="w-52 my-1"
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/11885766_10206058877245995_7230965478090577387_o_jG4q23h0Ic.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676779334838"
            alt=""
          />

          <img
            onClick={() => farmerOpen(true)}
            className="w-52 my-1"
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/gallerypictures/275787327_10222920773182855_6481897336364225561_n_vXpvMkX03G.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677152285630"
            alt=""
          />
          <img
            onClick={() => meetingOpen(true)}
            className="w-52 my-1"
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/gallerypictures/329911013_882338933037406_3320389964896127656_n_PsJjt6X6-.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677152282849"
            alt=""
          />
        </div>
        <div>

            <h1 className="lg:text-4xl md:text-3xl sm:text-2xl xs:text-2xl font-bold text-white lg:w-[500px] md:w-[450px] sm:w-[380px] xs:w-[200px] leading-[48px] py-3">আমরা বিশ্বাস করি, চেষ্টা ও দক্ষতাই পারে একটি কোম্পানিকে সামনের দিকে এগিয়ে নিতে</h1>
            <div className="lg:flex md:flex sm:block xs:block my-5 py-3">
                <img className="h-44" src="https://ik.imagekit.io/txh5evivcha5/pclstatic/fff-1_c2XRC_ljEV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676779332198" alt="" />
                <img className="h-44" src="https://ik.imagekit.io/txh5evivcha5/pclstatic/award__1__-fHXIKMpfb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676779334046" alt="" />
            </div>

            <h1 className="text-2xl font-bold text-white w-[500px] leading-[48px]">Business Awards We Got!</h1>

        </div>
        <div>
          <img
            onClick={() => cultureOpen(true)}
            className="w-52 my-1"
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/gallerypictures/11895141_10206058954887936_8630746175417721628_o_0prFkqFw9p.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677152279108"
            alt=""
          />

          <img
            onClick={() => togatherOpen(true)}
            className="w-52 my-1"
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/gallerypictures/305636423_10223710663529620_575842101561083367_n_dkG0WsCwDd.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677152281882"
            alt=""
          />
          <img
            onClick={() => csrOpen(true)}
            className="w-52 my-1"
            src="https://ik.imagekit.io/txh5evivcha5/pclstatic/gallerypictures/106732284_10219264392935634_6547591614756902474_n_4Ga2-Su8P.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677152286664"
            alt=""
          />
        </div>
      </div>
      </div>

      <Lightbox
        open={teamalbum}
        close={() => setTeamalbum(false)}
        slides={gallaryalbum.teamphotos}
      />
      <Lightbox
        open={farmer}
        close={() => farmerOpen(false)}
        slides={gallaryalbum.farmers}
      />
      <Lightbox
        open={meeting}
        close={() => meetingOpen(false)}
        slides={gallaryalbum.meeting}
      />
      <Lightbox
        open={culture}
        close={() => cultureOpen(false)}
        slides={gallaryalbum.culturalprogram}
      />
      <Lightbox
        open={togather}
        close={() => togatherOpen(false)}
        slides={gallaryalbum.gettogather}
      />
      <Lightbox
        open={csr}
        close={() => csrOpen(false)}
        slides={gallaryalbum.csr}
      />
    </>
  );
};

export default GalleryHome;
