// Ourteamcard.js
import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Ourteamcard = ({ name, designation, photo, src }) => {
  return (
    <div className="bg-white relative rounded-md border-gray-200 hover:border-green-600 hover:transition hover:ease-in hover:duration-200 border p-5 w-full max-w-sm shadow-md hover:shadow-xl m-5 text-center hover:after:absolute hover:after:h-full hover:after:w-full hover:after:bg-gradient-to-r from-[#6CBD41] to-[#27AC45] hover:after:left-5 hover:after:rounded-lg hover:after:shadow-2xl hover:after:top-5 hover:after:z-[-1]">
      <img src={photo} alt="photos" className="inline-block pb-5" />
      <h2 className="text-xl font-semibold text-[#383838] font-[Poppins] py-2">
        {name}
      </h2>
      <p className="py-3 font-[Poppins] text-green-700 hover:text-gray-600">
        {designation}
      </p>
      <div className="py-5 flex justify-center">
        <div className="w-8 h-8 bg-slate-500 hover:bg-green-600 hover:transition hover:ease-in-out hover:duration-200 rounded-full mx-2 text-white text-xl p-2 flex justify-center items-center">
          <FaFacebookF />
        </div>
        <div className="w-8 h-8 bg-slate-500 hover:bg-green-600 hover:transition hover:ease-in-out hover:duration-200 rounded-full mx-2 text-white text-xl p-2 flex justify-center items-center">
          <FaLinkedinIn />
        </div>
        <div className="w-8 h-8 bg-slate-500 hover:bg-green-600 hover:transition hover:ease-in-out hover:duration-200 rounded-full mx-2 text-white text-xl p-2 flex justify-center items-center">
          <FaTwitter />
        </div>
      </div>
      <Link
        className="bg-gradient-to-r from-[#6CBD41] to-[#27AC45] p-2 mt-2 inline-block text-white font-[Poppins] rounded-md hover:text-[#383838]"
        to={`${src}`}
      >
        Read more
      </Link>
    </div>
  );
};

export default Ourteamcard;
