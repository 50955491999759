import React from "react";
import { Link } from "react-router-dom";
const HeaderBreadCrumb = ({ url, title, link, breadCrubName }) => {
  return (
    <div
      className="h-72 w-full bg-cover bg-center"
      style={{
        backgroundImage: `url(${url})`,
      }}
    >
      <div className="bg-black opacity-75 h-full w-full flex justify-center items-center flex-col">
        <h1 className="text-white font-bold text-4xl">{title}</h1>

        <Link to={link} className="text-white ">
          <p className="text-blue-700 font-semibold text-lg py-3">{breadCrubName}</p>
        </Link>
      </div>
    </div>
  );
};

export default HeaderBreadCrumb;
