import React from "react";
import Teamdetails from "../components/article/Teamdetails";
import { ourteam } from "../data/contentdata";

const Chairman = () => {
  return (
    <div className="py-24 bg-white">
      
      <Teamdetails
        name={ourteam.chairman.name}
        designation={ourteam.chairman.designation}
        details={ourteam.chairman.shortsummary}
        photo={ourteam.chairman.photo}
      />
    </div>
  );
};

export default Chairman;
