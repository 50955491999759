import React from "react";
import CountUp from "react-countup";

const Statistics = () => {
  return (
    <div className="bg-gray-200 pt-10">
      <h2 className="text-4xl font-bold mb-4 text-cyan-900 text-center py-10">Clients We Served</h2>
      <div
        className="bg-gray-800 bg-cover bg-center py-24 px-4 md:px-8 lg:px-16"
        style={{
          backgroundImage:
            'url("https://ik.imagekit.io/txh5evivcha5/pclstatic/ISL/Untitled%20design%20(1)_5ZuVIsI-b.png?updatedAt=1716044107381")',
        }}
      >
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="bg-white shadow-md rounded-lg p-4 text-center">
            <h3 className="text-4xl font-bold text-blue-600">
              <CountUp
                className="text-blue-600"
                start={0}
                end={400}
                duration={2.5}
              />
              +
            </h3>
            <p className="text-gray-500">Project Completed</p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-4 text-center">
            <h3 className="text-4xl font-bold text-blue-600 ">
              <CountUp
                className="text-blue-600"
                start={0}
                end={620}
                duration={2.5}
              />
              +
            </h3>
            <p className="text-gray-500">Products Variation</p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-4 text-center">
            <h3 className="text-4xl font-bold text-blue-600">
              <CountUp
                className="text-blue-600"
                start={0}
                end={150}
                duration={2.5}
              />
              +
            </h3>
            <p className="text-gray-500">Happy Clients</p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-4 text-center">
            <h3 className="text-4xl font-bold text-blue-600">
              <CountUp
                className="text-blue-600"
                start={0}
                end={50}
                duration={2.5}
              />
              +
            </h3>
            <p className="text-gray-500">Project Winner</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
