import React from "react";
import { sisterconcerns } from "../../data/contentdata";
import { Link } from "react-router-dom";
const ConcernCard = () => {
  return (
    <div>
      <div className="flex justify-center flex-wrap gap-3 py-20 ">
        {sisterconcerns.map((item) => (
          <div className="shadow-lg w-[400px] h-[240px] text-[#383838] hover:text-white transition ease-in-out duration-500  hover:opacity-90 hover:bg-[url('https://ik.imagekit.io/txh5evivcha5/pclstatic/online-farmers-markets-2-889x592-1_-ieGcK6Hd.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675855122293')]  hover:shadow-xl transition ease-in-out duration-200 my-10 mx-3">
            <div className="flex justify-center text-center hover:bg-[#00000099] hover:h-[100%] ">
              <div className="relative ">
                <img className="flex items-center justify-center rounded-md p-2 py-5 absolute  top-[-50px] left-[25%] border-8 border-[#38383817]" src={item.logo} alt="" />
                <h1 className="text-xl font-bold  py-2 mt-20 ">{item.name}</h1>
                <div>
                <Link className="bg-gradient-to-r from-[#6CBD41] to-[#27AC45] hover:bg-red-300 hover:text-gray-800 hover:shadow-lg py-3 px-3 font-semibold text-white rounded-md my-2 inline-block" to={item.path}> Read more</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConcernCard;
