import React from "react";
import "./Beatbutton.css";
// import { FaPlay } from "react-icons/fa";
const BeatButton = () => {
  return (
    <div class="boton relative p-10"><a href="dsd"><i class="fas fa-play text-2xl"></i></a></div>
  );
};

export default BeatButton;
