import React from "react";

const Basicflaks = ({title, message}) => {
  return (
    <div className="w-[full] flex justify-center my-5 mx-20 ">
      <div className=" bg-[#25AC45] py-16 px-10 ">
        <h1 className="text-3xl font-extrabold text-white py-3 ">{title}</h1>
        <p className="text-white">
         {message}
        </p>
      </div>
    </div>
  );
};

export default Basicflaks;
