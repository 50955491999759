import React from "react";
import { recruitments } from "../../data/contentdata";
import {
  FaDotCircle,
  FaRegCalendarAlt,
  FaMapMarkerAlt,
  FaInfo,
} from "react-icons/fa";

const RecruitmentCard = () => {
  return (
    <div className="p-20 font-[Poppins] w-full">
      <div className="flex sm:flex-wrap xs:flex-wrap lg:flex-nowrap md:flex-nowrap ">
        {recruitments.map((item) => (
          <div className="p-10 m-2 border hover:shadow-lg" key={item.id}>
            <div className="flex">
              <img
                className="h-16 w-16 object-cover rounded-full border m-2"
                src={item.companylogo}
                alt=""
              />
              <div className="py-4">
                <h1 className="text-xl font-semibold pb-2">{item.jobtitle}</h1>
                <p className="flex items-center text-[#a3a3a3]">
                  <FaDotCircle className="text-xs text-[#a3a3a3] mr-1" />
                  {item.companyname}
                </p>
                <p className="flex items-center text-[#a3a3a3]">
                  <FaInfo className="text-xs text-[#a3a3a3] mr-1" />
                  {item.department}
                </p>
                <p className="flex items-center text-[#a3a3a3]">
                  <FaMapMarkerAlt className="text-xs text-[#a3a3a3] mr-1" />
                  {item.location}
                </p>
                <p className="flex items-center text-[#a3a3a3]">
                  <FaRegCalendarAlt className="text-xs text-[#a3a3a3] mr-1" />
                  {item.jobtype}
                </p>
                <p></p>
              </div>
            </div>
            <hr />
            <div className="py-4">
              <p>
                <span className="font-semibold">Circuler date:</span>{" "}
                {item.circulardate}
              </p>
              <p>
                <span className="font-semibold">Salary : </span>
                {item.salary}
              </p>
              <p>
                <span className="font-semibold">Sector : </span>
                {item.sector}
              </p>
              <p>
                {" "}
                <span className="font-semibold">Job Duration:</span>{" "}
                {item.jobduration}
              </p>
            </div>
            <hr />
            <div className="py-4">
              <p className="font-bold pt-2 ">Job Responsibilities : </p>
              <div>
                {item.jobdescription.map((items) => (
                  <li className="flex items-start py-2 text-[#383838]">
                    {" "}
                    -- {items.res}
                  </li>
                ))}
              </div>
            </div>
            <hr className="py-2"/>
            <div className="flex justify-center py-4">
            <a href={item.joblink} className="bg-gradient-to-r from-[#6CBD41] to-[#27AC45] inline-block rounded-md px-4 py-2 font-semibold text-white hover:shadow-xl hover:scale-[1.05] transition ease-in-out "> Apply Now </a>
            </div>

            
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecruitmentCard;
