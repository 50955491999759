import React from "react";
import SmallUpperhead from "./SmallUpperhead";
import { whoweare } from "../../data/contentdata";
const Leftwho = () => {
  return (
    <div className="w-full font-['Poppins']">
      <div className="lg:p-20 sm:p-10 xs:p-10" >
        <SmallUpperhead />

        <h1 className="lg:text-4xl sm:text-3xl xs:text-2xl text-[#383838] leading-[48px] font-bold pr-20 font-['Poppins']">
          {whoweare.maiintitle}
        </h1>

        <p className="lg:leading-[34px] py-5 text-[#383838] sm:leading-[28px]">
          {whoweare.leftdescription}
        </p>

        <div className="flex flex-wrap gap-2">
          {whoweare.logo.map((item, index) => (
            <div className="lg:p-5 md:p-4 sm:p-3 xs:p-2">
              <img src={item.iconlogo} key={index} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Leftwho;
